import { httpFetchMixpanelEvent } from "../httpRequests";
import { useMixpanel } from "../MixpanelProvider";


export const useTrackEvent = () => {
    const mixpanel = useMixpanel();


    const track = (eventType = '', data = {}, timeoutDelay = 5000, handleAfterTrack = () => { }) => {
        if (!mixpanel) return;


        setTimeout(() => {
            console.log('mixpanel_event_track', eventType, data);
            mixpanel.track(eventType, data);
            handleAfterTrack()
        }, timeoutDelay);
    };
    const triggerBackendEvent = (eventType = '', data = {}, onSuccess = () => { }) => {
        const timeoutDelay = 5000
        setTimeout(() => {
            httpFetchMixpanelEvent(eventType, data)
                .then(res => {
                    console.log('mixpanel_event_triggerBackendEvent', res);
                    onSuccess(res)
                })
                .catch(err => {
                    console.error('mixpanel_event_triggerBackendEvent', err);
                });
        }, timeoutDelay);

    }
    const timeEvent = (eventType = '') => {
        if (!mixpanel) return;
        mixpanel.time_event(eventType);
    };
    return { track, timeEvent, triggerBackendEvent };
};

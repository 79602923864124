export const PARAM_ROOKIE_PROFILE = "rp";
export const PARAM_USER_GROUP = "ug";
export const PARAM_REFERRAL_CODE = "r";
export const PARAM_PERSONA = "persona";
export const PARAM_FLAVOR = "fl";
export const PARAM_PATH = "path";
export const PARAM_GENDER = "m";
export const PARAM_SHARE = "share";
export const PARAM_GOAL = "goal";
export const PARAM_ROOKIE_ID = "rookie_id";
export const PARAM_UTM_CONTENT = "utm_content";
export const PARAM_EMAIL = "email";
export const PARAM_INVITE = "invite";
export const PARAM_DISCOUNT_ID = "user_discount_id";
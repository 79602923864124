import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";
import { useViewportSize } from "../../hooks/useViewportSize";
import {
    PARAM_PERSONA,
    PARAM_UTM_CONTENT,
    ROUTE_LANDING_ROOKIE,
} from "../../utilities/constants";
import AnonymousTourCTA from "./AnonymousTourCTA";
import AnonymousTourSlider from "./AnonymousTourSlider";
import { KNOWN_COOKIES } from "../../utilities/constants/user";
import Cookies from "js-cookie";

const useStyles = createUseStyles((theme) => ({
    anonymousTour: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: ({ vh }) => `calc(${vh}px * 100 - 66px)`,
        justifyContent: "space-around",
        background: "#fff",
        padding: 20,
        [theme.mUp]: {
            padding: 0,
            justifyContent: "center",
            height: ({ vh }) => `calc(${vh}px * 100 - 115px)`,
        },
    },
}));
const AnonymousPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location?.search);
    const { vh } = useViewportSize();
    const classes = useStyles({ vh });

    const role =
        location.pathname === ROUTE_LANDING_ROOKIE ? "rookie" : "leader";
    const getCorrectPersona = () => {
        if (!!searchParams.get(PARAM_PERSONA)) {
            if (
                +searchParams.get(PARAM_PERSONA) > 11 ||
                +searchParams.get(PARAM_PERSONA) < 7
            ) {
                return 0;
            } else {
                return +searchParams.get(PARAM_PERSONA);
            }
        } else {
            return 0;
        }
    };
    const persona = getCorrectPersona();

    const useUtmParams = () => {
        const utmParams = {};
        searchParams.forEach((value, key) => {
            if (key.startsWith("utm_")) {
                utmParams[key] = value;
            }
        });

        return utmParams;
    };

    const utmParams = useUtmParams();

    useEffect(() => {
        if (searchParams) {
            Object.keys(utmParams).map((key) =>
                Cookies.set(key, utmParams[key], {
                    domain: KNOWN_COOKIES.mainDomain,
                    secure: true,
                })
            );
        }
    }, [searchParams]);

    return (
        <div className={classes.anonymousTour}>
            <AnonymousTourSlider persona={persona} role={role} />
            <AnonymousTourCTA />
        </div>
    );
};

export default AnonymousPage;

import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../../translations";
import RookieCTABar from "../../RookieCTABar/RookieCTABar";

const useStyles = createUseStyles((theme) => ({
    stickContainer: {
        position: "-webkit-sticky",
        position: "sticky",
        top: 0,
        zIndex: 995,
        background: theme.colors.white,
        boxShadow:
            "8px 0px 0px 0px rgb(255, 255, 255), -8px 0px 0px 0px rgb(255, 255, 255)",
    },
    connectContainer: {
        display: "flex",
        justifyContent: "space-between",
        padding: `${theme.spacing * 4}px ${theme.spacing * 2}px ${
            theme.spacing * 1.5
        }px`,
        gap: theme.spacing,
        "& .connectNowButton": {
            fontSize: "18px",
            width: "min-content",
            padding: 0,
            textWrap: "nowrap",
            color: theme.colors.white,
            margin: 0,
            // padding: `${theme.spacing / 2}px ${theme.spacing * 2}px`,
            [theme.mUp]: {
                fontSize: "24px",
                // padding: `${theme.spacing}px ${theme.spacing * 3}px`,
            },
        },
        [theme.mUp]: {
            padding: `${theme.spacing * 4}px 0px ${theme.spacing * 2.5}px`,
        },
    },
    fullName: {
        fontSize: "28px",
        color: theme.colors.darkPurple,
        fontWeight: 700,
        lineHeight: "100%",
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxHeight: "4.5em",
        [theme.mUp]: {
            fontSize: "36px",
            letterSpacing: "-0.02em",
        },
    },
}));

const NameCTASection = ({ rookie, onSignUpHandlerRedirect }) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const classes = useStyles({});

    return (
        <div className={classes.stickContainer}>
            <div className={classes.connectContainer}>
                <h1 className={classes.fullName}>{rookie?.full_name}</h1>
                {rookie && (
                    <RookieCTABar
                        className={"connectNowButton"}
                        onGift={onSignUpHandlerRedirect}
                        fontWeight={500}
                        message={t("rookie_profile_page.sign_up")}
                    />
                )}
            </div>
        </div>
    );
};

export default NameCTASection;

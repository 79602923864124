import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import AvatarSection from "./AvatarSection/AvatarSection";
import NameCTASection from "./NameCTASection/NameCTASection";
import DescriptionTopicsSection from "./DescrptionTopicsSection/DescriptionTopicsSection";
import { RookieMembershipSection } from "../RookieMembershipSection/RookieMembershipSection";
import RookieGoalSection from "../RookieGoalSection/RookieGoalSection";

const useStyles = createUseStyles((theme) => ({}));

const GeneralRookieInfo = ({ rookie, onSignUpHandlerRedirect }) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const classes = useStyles({});

    return (
        <>
            <NameCTASection
                rookie={rookie}
                onSignUpHandlerRedirect={onSignUpHandlerRedirect}
            />
            <DescriptionTopicsSection rookie={rookie} />
            <RookieMembershipSection rookieProfile={rookie} />
            <RookieGoalSection rookieProfile={rookie} />
        </>
    );
};

export default GeneralRookieInfo;

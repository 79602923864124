import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import Button from "../../../components/Button";
import {
    InfoLightCircleIcon,
    MegaphoneIcon,
    MicromorgiIcon,
    OpenGiftIcon,
    SurprisesIcon,
} from "../../../assets/icons/icons";
import { ChooseSupportModal } from "../ChooseSupportModal/ChooseSupportModal";
import customAxios from "../../../utilities/axios";
import { APP_ROUTE_SIGNUP_LEADER_SOCIAL } from "../../../utilities/constants/appRouteNames";
import {
    getAppLink,
    redirectToExternalLink,
    remapURLSearchParams,
} from "../../../utilities/utils";
import { getUserGroup } from "../../../utilities/constants/user";
import {
    PARAM_DISCOUNT_ID,
    PARAM_ROOKIE_PROFILE,
    PARAM_USER_GROUP,
} from "../../../utilities/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useViewportSize } from "../../../hooks/useViewportSize";
import cx from "classnames";
import { useTrackEvent } from "../../../utilities/mixpanel/hooks/useTrackEvent";
import { MIXPANEL_TYPES } from "../../../utilities/constants/mixpanel";
import Tippy from "@tippyjs/react";

const useStyles = createUseStyles((theme) => ({
    container: {
        padding: `0px ${theme.spacing * 2}px`,
        [theme.mUp]: {
            padding: `0px`,
        },
    },
    root: {
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 10px 0px",
        borderRadius: "14px",
    },
    discount: {
        borderRadius: 8,
        border: "2px solid #FC9F21",
        background: "#FBFBE1",
        boxShadow: "0px 2px 8px 0px rgba(2, 0, 49, 0.25)",
        "& $imageContent": {
            border: "none !important",
        },
    },
    oldPrice: {
        textDecoration: "line-through",
        opacity: 0.5,
    },
    discountLabel: {
        color: "#FFBF1B",
        fontFamily: "Inter",
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        justifyContent: "center !important",
    },
    megaphoneIcon: {
        fill: "transparent !important",
    },
    discountValue: {
        position: "absolute",
        left: 8,
        top: 16,
        display: "flex",
        padding: "4px 8px",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
        borderRadius: 6,
        background: "linear-gradient(0deg, #FC9F21 0%, #FC9F21 100%)",
        color: "#FFF",
        fontFamily: "Inter",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        textTransform: "capitalize",
    },
    openGiftIcon: {
        position: "absolute",
        right: 16,
        top: 16,
        height: 40,
        width: 40,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 4,
    },
    achievementsTitle: {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "28px",
        margin: "16px 0 16px 0",
        borderBottom: "1px solid #7F829D33",
        paddingBottom: theme.spacing * 2,
        [theme.mUp]: {
            fontSize: "24px",
        },
    },
    slider: {
        margin: "0 auto 16px",

        "& *": {
            minWidth: 0,
            minHeight: 0,
        },
    },
    rookieMembership: {
        position: "relative",
        display: "flex !important",
        flexDirection: "column",
        gap: theme.spacing,
        padding: theme.spacing * 2,
    },
    imageContent: {
        position: "relative",
        width: "100%",
        paddingBottom: theme.spacing * 2.5,
        height: "116px",
        borderRadius: 12,
        background: "linear-gradient(135deg, #808080, #3d3d3d)",
        "& img": {
            width: "100%",
            // borderRadius: 12,
            // objectFit: "cover",
            height: "116px",
        },
        [theme.sUp]: {
            height: "200px",
        },
    },
    infoContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        fontSize: "12px",
    },
    tooltipBox: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing * 1.5,
    },
    infoBox: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing / 2,

        textAlign: "right",
        fontFamily: "Inter",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "140%",
        maxWidth: "300px",

        overflow: "hidden",
        whiteSpace: "nowrap",
        minWidth: 0,

        "& p": {
            margin: 0,
            flexShrink: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "block",
            maxWidth: "100%",
        },
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "#8649D1",
    },
    customDotsContainer: {
        margin: "0 auto",
        display: "flex",
        position: "relative",
        gap: 4,
        justifyContent: "center",
        width: "100%",
        maxWidth: 310,
        top: "-20px",
        zIndex: 2,
    },
    customDot: {
        height: "7px",
        width: "7px",
        background: "#D9D9D9",
        opacity: "0.7",
        borderRadius: "50%",
        "&.active": {
            opacity: "1",
            background: "#00063D",
        },
    },
    button: {
        width: "100%",
        maxWidth: "100%",
        minHeight: 56,
        display: "flex",
        "& span": {
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
        },
        padding: `${theme.spacing / 1.5}px ${theme.spacing * 3}px`,
    },
    buttonContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: `${theme.spacing / 4}px`,
        "& svg": {
            width: "17px",
            height: "17px",
        },
    },
    popoverContent: {
        maxWidth: 315,
        padding: theme.spacing,
        textAlign: "center",
    },
    tooltipTrigger: {
        "& svg": {
            cursor: "pointer",
            "& path": {
                opacity: 1,
            },
        },
    },
    tippy: {
        padding: "4px",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        width: 315,
        wordBreak: "break-word",
        maxHeight: 66,
        borderRadius: 4,
        borderRadius: "14px",
        zIndex: "3",
        overflow: "auto",
    },
    "@media (min-width: 768px)": {
        discountLabel: {
            fontSize: 18,
        },
    },
}));
export const RookieMembershipSection = ({ rookieProfile }) => {
    const classes = useStyles();
    const [contentIndex, setContentIndex] = useState(0);
    const { track } = useTrackEvent();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const { vw } = useViewportSize();
    const isMobile = vw * 100 <= 576;
    const [packagesList, setPackagesList] = useState([]);
    const viewableSlide = packagesList[contentIndex];
    const discount = viewableSlide?.discount;
    const calculateDiscount = (price) => {
        const _price = Number(
            +price * (discount ? 1 - +discount.discount_percentage : 1)
        );
        return _price % 1 ? _price.toFixed(1) : _price;
    };
    const hasDiscount = Boolean(discount);
    const [selectedMembership, setSelectedMembership] = useState();

    const { search } = useLocation();
    const isUserCanSeeCheckoutStuff = search.includes("checkout");
    const [openChooseSupportModal, setOpenChooseSupportModal] = useState(false);

    const [country, setCountry] = useState("");

    const isBrazil = country === "Brazil";

    useEffect(() => {
        fetch("https://ipapi.co/json/")
            .then((response) => response.json())
            .then((data) => {
                setCountry(data.country_name);
            })
            .catch((error) =>
                console.error("Error fetching geolocation:", error)
            );
    }, []);

    const currencySymbol = isBrazil ? "R$" : "$";

    const RedirectToSignUp = useCallback(() => {
        track(
            MIXPANEL_TYPES.CTA_ANON_PAGE,
            {
                cta: "subscribe",
                host_name: rookieProfile.username,
                url: location.pathname,
                utm: !!search ? search : undefined,
                host_distinct_id: rookieProfile?.id,
            },
            0,
            () => {
                redirectToExternalLink(
                    getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
                        ...remapURLSearchParams(search),
                        {
                            name: PARAM_ROOKIE_PROFILE,
                            value: rookieProfile?.username,
                        },
                        {
                            name: PARAM_USER_GROUP,
                            value: getUserGroup(),
                        },
                        {
                            name: PARAM_DISCOUNT_ID,
                            value: discount?.id,
                        },
                    ])
                );
            }
        );
    }, [search, rookieProfile]);

    useEffect(() => {
        const fetch = async () => {
            if (rookieProfile.id) {
                const { data } = await customAxios.get(
                    `/subscriptions/rookies/${rookieProfile?.id}/packages`
                );
                const filteredData = data
                    .filter((pack) => !!pack.group)
                    .sort(
                        (a, b) =>
                            Number(Boolean(b.discount)) -
                            Number(Boolean(a.discount))
                    );
                setPackagesList(filteredData);
            }
        };
        fetch();
    }, [rookieProfile?.id]);

    const settings = {
        infinite: false,
        speed: 200,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        adaptiveHeight: true,
        afterChange: (current) => {
            setContentIndex(current);
        },
    };

    const handleChooseSupportModal = () => {
        setOpenChooseSupportModal(!openChooseSupportModal);
    };

    const defaultImageHeight = isMobile ? "114px" : "198px";
    const imageHeight = isMobile ? "116px" : "200px";

    return (
        <div className={classes.container}>
            <h4 className={classes.achievementsTitle}>
                {t("rookie_profile_page.membership.title")}
            </h4>
            <div
                className={cx(
                    classes.root,
                    hasDiscount ? classes.discount : undefined
                )}
            >
                <Slider {...settings} className={classes.slider}>
                    {packagesList?.map((item) => {
                        const hasDefaultImage =
                            item.group.url?.includes(
                                "default_group_image.png"
                            ) || false;
                        return (
                            <div
                                key={item.id}
                                className={classes.rookieMembership}
                            >
                                <div
                                    className={classes.imageContent}
                                    style={
                                        hasDefaultImage
                                            ? {
                                                  background: "none",
                                                  border: "1px solid black",
                                              }
                                            : {}
                                    }
                                >
                                    {!!item?.group?.url && (
                                        <img
                                            style={{
                                                borderRadius: hasDefaultImage
                                                    ? "8px"
                                                    : "12px",
                                                objectFit: hasDefaultImage
                                                    ? "contain"
                                                    : "cover",
                                                height: hasDefaultImage
                                                    ? defaultImageHeight
                                                    : imageHeight,
                                            }}
                                            src={item.group.url}
                                            alt="membership"
                                        />
                                    )}
                                    <OpenGiftIcon
                                        className={classes.openGiftIcon}
                                    />
                                    {hasDiscount && (
                                        <span className={classes.discountValue}>
                                            {t(
                                                "rookie_profile_page.membership.for_you_percentage_off",
                                                {
                                                    PERCENTAGE:
                                                        +discount.discount_percentage *
                                                        100,
                                                }
                                            )}
                                        </span>
                                    )}
                                </div>
                                <div className={classes.infoContainer}>
                                    <div className={classes.infoBox}>
                                        <SurprisesIcon />
                                        {t(
                                            "rookie_profile_page.membership.surprises",
                                            {
                                                SURPRISES_COUNT:
                                                    item.surprises_count,
                                            }
                                        )}
                                    </div>
                                    <div className={classes.tooltipBox}>
                                        <div className={classes.infoBox}>
                                            <MegaphoneIcon
                                                className={
                                                    classes.megaphoneIcon
                                                }
                                            />
                                            <p>{item.group.name}</p>

                                            {!!item?.group?.description && (
                                                <Tippy
                                                    theme="light"
                                                    placement={"bottom"}
                                                    trigger="click"
                                                    inlinePositioning={true}
                                                    interactive={true}
                                                    className={classes.tippy}
                                                    content={
                                                        item.group.description
                                                    }
                                                >
                                                    <span
                                                        className={
                                                            classes.tooltipTrigger
                                                        }
                                                    >
                                                        <InfoLightCircleIcon
                                                            width="10px"
                                                            height="10px"
                                                        />
                                                    </span>
                                                </Tippy>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <Button
                                    data-primary
                                    className={classes.button}
                                    onClick={
                                        isUserCanSeeCheckoutStuff
                                            ? () => {
                                                  setSelectedMembership(item);
                                                  handleChooseSupportModal();
                                              }
                                            : RedirectToSignUp
                                    }
                                >
                                    <div>
                                        <span>
                                            {t(
                                                "rookie_profile_page.membership.subscribe"
                                            )}
                                        </span>
                                        {discount && (
                                            <span
                                                className={
                                                    classes.discountLabel
                                                }
                                            >
                                                {t(
                                                    "rookie_profile_page.membership.for_percentage_off",
                                                    {
                                                        PERCENTAGE:
                                                            discount?.discount_percentage *
                                                            100,
                                                    }
                                                )}
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        <div className={classes.buttonContent}>
                                            <MicromorgiIcon />
                                            {calculateDiscount(item.amount)}
                                            {`(${currencySymbol}${calculateDiscount(
                                                item.dollar_amount
                                            )})`}
                                            {t(
                                                "rookie_profile_page.membership.month"
                                            )}
                                        </div>
                                        {hasDiscount && (
                                            <div
                                                className={cx(
                                                    classes.buttonContent,
                                                    classes.oldPrice
                                                )}
                                            >
                                                <MicromorgiIcon />
                                                {item.amount}
                                                {`(${currencySymbol}${item.dollar_amount})`}
                                                {t(
                                                    "rookie_profile_page.membership.month"
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </Button>
                            </div>
                        );
                    })}
                </Slider>

                <div className={classes.customDotsContainer}>
                    {packagesList?.map((_, idx) => (
                        <div
                            key={idx}
                            className={`${classes.customDot} ${
                                idx === contentIndex ? "active" : ""
                            }`}
                        />
                    ))}
                </div>
            </div>
            {openChooseSupportModal && (
                <ChooseSupportModal
                    onClose={handleChooseSupportModal}
                    content={selectedMembership}
                    rookieProfile={rookieProfile}
                />
            )}
        </div>
    );
};

import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import { MURAL_TYPES } from "./MuralItem";
import {
    BigLockIcon,
    MediaPackagePhotoIcon,
    MediaPackageVideoIcon,
    MicromorgiIcon,
} from "../../../assets/icons/icons";
import { useViewportSize } from "../../../hooks/useViewportSize";

const useStyles = createUseStyles((theme) => ({
    unpaidContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        inset: 0, // offset → inset у CSS
        zIndex: 1,
        width: "100%",
        height: "100%",
    },
    unpaidContentWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing * 1.75,
    },
    packageName: {
        color: theme.colors.white,
        textAlign: "center",
        fontWeight: 800,
        textTransform: "capitalize",
        fontSize: "20px",

        [theme.mUp]: {
            fontSize: "24px",
        },
    },
    counterItemsContainer: {
        display: "flex",
        gap: theme.spacing,
        color: theme.colors.white,
        fontWeight: 600,
        lineHeight: "140%", // 28px
        alignItems: "center",
        fontSize: "20px",

        [theme.mUp]: {
            fontSize: "30px",
        },
    },
    counterItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "12px",
        gap: theme.spacing,
        borderRadius: 12,
        background: "#7f7f7f",
        padding: `0 ${theme.spacing}px`,
        color: theme.colors.white,
        fontWeight: 700,
        height: 15,

        [theme.mUp]: {
            fontSize: "18px",
            gap: theme.spacing * 1.75,
            height: 23,
        },
    },
}));

const MuralPackageItem = ({ mural, onSignUpHandlerRedirect }) => {
    const { vw } = useViewportSize();
    const isMobile = vw * 100 <= 768;
    const isMediaPackage = mural?.mural_type === MURAL_TYPES.MEDIA_PACKAGES;
    const isPaid =
        isMediaPackage && mural?.media_packages_contents
            ? !!mural?.media_packages_contents[0]?.url
            : !!mural?.url;
    const classes = useStyles({});

    return (
        <>
            {!isPaid && (
                <>
                    <div className={classes.unpaidContent}>
                        <div className={classes.unpaidContentWrapper}>
                            <BigLockIcon />
                            <div className={classes.packageName}>
                                {mural?.name}
                            </div>
                            <div className={classes.counterItemsContainer}>
                                {!!mural?.media_packages_contents_counts
                                    ?.image && (
                                    <div className={classes.counterItem}>
                                        {mural?.media_packages_contents_counts
                                            ?.image || 0}
                                        <MediaPackagePhotoIcon />
                                    </div>
                                )}
                                {!!mural?.media_packages_contents_counts
                                    ?.video && (
                                    <div className={classes.counterItem}>
                                        {mural?.media_packages_contents_counts
                                            ?.video || 0}
                                        <MediaPackageVideoIcon />
                                    </div>
                                )}
                            </div>
                            <div className={classes.counterItemsContainer}>
                                <MicromorgiIcon
                                    height={isMobile ? 20 : 32}
                                    width={isMobile ? 20 : 32}
                                />
                                {mural?.amount}
                            </div>
                        </div>
                    </div>
                    <div className={classes.mediaWrapper}>
                        <img
                            src={mural?.media_packages_contents[0]?.blurred_url}
                            alt="image"
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default MuralPackageItem;

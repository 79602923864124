import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { createUseStyles } from "react-jss";
import { httpFetchRookie } from "../../httpRequests/rookie.http";
import {
    getAppLink,
    redirectToExternalLink,
    remapURLSearchParams,
} from "../../utilities/utils";
import Spinner from "../../components/Spinner";
import { APP_ROUTE_SIGNUP_LEADER_SOCIAL } from "../../utilities/constants/appRouteNames";
import {
    PARAM_ROOKIE_PROFILE,
    PARAM_SHARE,
    PARAM_USER_GROUP,
} from "../../utilities/constants";
import { getUserGroup } from "../../utilities/constants/user";
import CTAModal from "../../components/CTAModal";
import Animation from "../../components/Animation";
import alert from "../../assets/animations/Alert-1.json";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../translations";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Context from "../../Context";
import { useDispatch, useSelector } from "react-redux";
import { selectThemeMode } from "../../store/settingSlice";
import {
    setCardIndex,
    setLeaderCardIndex,
    setRefreshCarousel,
} from "../../store/carouselSlice";
import { httpGetTranslation } from "../../httpRequests/translation.http";
import i18n from "../../i18n";
import SubscriptionsPlansModal from "./SubscriptionsPlansModal/SubscriptionsPlansModal";
import MorgisPackagesModal from "./MorgisPackagesModal/MorgisPackagesModal";
import MorgisCheckoutModal from "./MorgisCheckoutModal/MorgisCheckoutModal";
import GeneralRookieInfo from "./GeneralRookieInfo/GeneralRookieInfo";
import RookieMural from "./RookieMural/RookieMural";
import AvatarSection from "./GeneralRookieInfo/AvatarSection/AvatarSection";
import ShareModal from "../../components/ShareModal";
import { useTrackEvent } from "../../utilities/mixpanel/hooks/useTrackEvent";
import { MIXPANEL_TYPES } from "../../utilities/constants/mixpanel";

const useStyles = createUseStyles((theme) => ({
    rookieProfile: {
        paddingBottom: theme.spacing * 2,
    },
    container: {
        maxWidth: 375,
        width: "100%",
        margin: "0 auto",
        [theme.mUp]: {
            maxWidth: 600,
        },
    },
    alertAnimation: {
        margin: "0 auto",
        height: "220px",
        width: "240px",
        padding: "15px",
        [theme.mUp]: {
            padding: 0,
        },
    },
}));

export const fetchComponentData = async ({ param: rookieUserName }) => {
    if (!rookieUserName) return null;
    const rookieDataResponse = await httpFetchRookie(rookieUserName);
    const rookieData = rookieDataResponse.data;
    if (!rookieData) return null;

    const initialLanguage = i18n.language.split("_")[0];

    // Aggregating items to translate with a unique key
    const itemsToTranslate = [
        ...rookieData.chat_topics.map((item) => ({
            id: item.id,
            text: item.name,
            type: "chat_topic",
        })),
        ...rookieData.favorite_activities.map((item) => ({
            id: item.id,
            text: item.name,
            type: "favorite_activity",
        })),
    ];

    // Only add wishes if they are not null
    if (
        rookieData?.type_attributes?.path?.id &&
        rookieData?.type_attributes?.path?.name
    ) {
        itemsToTranslate.push({
            id: rookieData.type_attributes.path.id,
            text: rookieData.type_attributes.path.name,
            type: "wish",
        });
    }

    // Preparing contents for translation API
    const contentsToTranslate = itemsToTranslate.map((item) => ({
        id: `${item.type}_${item.id}`,
        text: item.text,
    }));

    const translationResponse = await httpGetTranslation({
        contents: contentsToTranslate,
        target: initialLanguage,
    });

    // Creating a map from the translation response
    const translatedItemsMap = new Map(
        translationResponse.data.map((item) => [item.id, item.text])
    );

    // Function to get the translated text using combined key
    const getTranslatedText = (id, type) => {
        return (
            translatedItemsMap.get(`${type}_${id}`) ||
            itemsToTranslate.find(
                (item) => item.id === id && item.type === type
            ).text
        );
    };

    // Distributing translations back to the appropriate fields
    const translatedChatTopics = rookieData?.chat_topics?.map((topic) => ({
        ...topic,
        name: getTranslatedText(topic?.id, "chat_topic"),
    }));

    const translatedFavActivities = rookieData?.favorite_activities?.map(
        (activity) => ({
            ...activity,
            name: getTranslatedText(activity?.id, "favorite_activity"),
        })
    );

    const translatedWishes = rookieData?.type_attributes?.path?.id
        ? {
              ...rookieData?.type_attributes?.path,
              name: getTranslatedText(
                  rookieData?.type_attributes?.path?.id,
                  "wish"
              ),
          }
        : null;

    // Merging translated data back into rookieData
    const rookieDataWithTranslation = {
        ...rookieData,
        chat_topics: translatedChatTopics,
        favorite_activities: translatedFavActivities,
        type_attributes: {
            ...rookieData?.type_attributes,
            path: translatedWishes,
        },
    };

    return rookieDataWithTranslation;
};

const RookieProfile = (props) => {
    const themeMode = useSelector(selectThemeMode);
    const classes = useStyles({ themeMode });
    const context = useContext(Context);
    const dispatch = useDispatch();
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const { track } = useTrackEvent();
    const history = useHistory();
    const [rookie, setRookie] = useState(context?.componentData);
    const [isLoading, setIsLoading] = useState(true);
    const [showSubscriptionPlansModal, setShowSubscriptionPlansModal] =
        useState(false);
    const [showMorgiPackagesModal, setShowMorgiPackagesModal] = useState(false);
    const [showMorgisCheckoutModal, setShowMorgisCheckoutModal] =
        useState(false);

    const { username: rookieUserName } = useParams();
    const { search, state } = useLocation();
    const lang = i18n.language.split("_")[0];
    const [showShareModal, setShowShareModal] = useState(false);

    const rookieGender = useMemo(() => {
        switch (rookie?.type_attributes?.gender?.id) {
            case 1:
                return "male";
            case 2:
                return "female";
            default:
                return "other";
        }
    }, [rookie]);

    const parsedUrl =
        typeof window !== "undefined" ? new URL(window.location.href) : "";

    useEffect(() => {
        if (state?.rookieCardIndex > -1) {
            dispatch(setCardIndex(state?.rookieCardIndex));
            dispatch(setLeaderCardIndex(state?.leaderCardIndex));
            dispatch(setRefreshCarousel(false));
        }

        if (
            (rookieUserName !== rookie?.username &&
                rookieUserName !== rookie?.type_attributes?.nickname) ||
            !rookie ||
            (rookie && !Object.values(rookie).length)
        ) {
            fetchRookie();
        } else {
            setIsLoading(false);
        }
    }, [rookieUserName, rookie, state]);

    useEffect(() => {
        fetchRookie();
    }, [lang]);

    const [isOpenRookieNotFound, setIsOpenRookieNotFound] = useState(false);

    const fetchRookie = async () => {
        try {
            setIsLoading(true);
            const data = await fetchComponentData({ param: rookieUserName });
            setRookie(data);
            setIsLoading(false);
        } catch (error) {
            setIsOpenRookieNotFound(true);
            console.log(error);
        }
    };

    const onSignUpHandlerRedirect = useCallback(() => {
        track(
            MIXPANEL_TYPES.CTA_ANON_PAGE,
            {
                cta: "sign_up",
                host_name: props?.match.params.username,
                url: props?.match.url,
                utm: !!search ? search : undefined,
                host_distinct_id: rookie?.id,
            },
            0,
            () => {
                redirectToExternalLink(
                    getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
                        ...remapURLSearchParams(search),
                        {
                            name: PARAM_ROOKIE_PROFILE,
                            value: rookie?.username,
                        },
                        {
                            name: PARAM_USER_GROUP,
                            value: getUserGroup(),
                        },
                    ])
                );
            }
        );
    }, [search, rookie]);

    return (
        <>
            <Helmet>
                {rookie?.photos?.length && (
                    <meta
                        property="og:image"
                        content={`${rookie.photos[0].url}`}
                    />
                )}
                {rookie?.full_name && (
                    <meta
                        property="og:title"
                        content={`${rookie?.full_name} - Morgis.com`}
                    />
                )}
            </Helmet>
            {isLoading ? (
                <Spinner />
            ) : (
                <div className={classes.rookieProfile}>
                    <AvatarSection
                        rookie={rookie}
                        onShare={() => setShowShareModal(true)}
                    />
                    <div className={classes.container}>
                        <GeneralRookieInfo
                            rookie={rookie}
                            onSignUpHandlerRedirect={onSignUpHandlerRedirect}
                        />

                        <RookieMural
                            rookie={rookie}
                            onSignUpHandlerRedirect={onSignUpHandlerRedirect}
                            openSubscriptionModal={() => {
                                track(MIXPANEL_TYPES.CTA_ANON_PAGE, {
                                    cta: "open surprise",
                                    host_name: props?.match.params.username,
                                    url: props?.match.url,
                                    utm: !!search ? search : undefined,
                                    host_distinct_id: rookie?.id,
                                });
                                setShowSubscriptionPlansModal(true);
                            }}
                            openMorgiPackagesModal={() => {
                                setShowMorgiPackagesModal(true);
                            }}
                        />
                    </div>
                </div>
            )}
            {isOpenRookieNotFound && (
                <CTAModal
                    isBackButton={false}
                    onClick={() => history.push("/")}
                    onClose={() => history.push("/")}
                    title={t("attention_modal.title")}
                    description={t("attention_modal.description")}
                    buttonText={t("attention_modal.go_home")}
                >
                    <Animation
                        animationData={alert}
                        animationStyleClass={classes.alertAnimation}
                    />
                </CTAModal>
            )}
            {showSubscriptionPlansModal && (
                <SubscriptionsPlansModal
                    rookieId={rookie?.id}
                    hostName={rookieUserName}
                    onClose={() => {
                        setShowSubscriptionPlansModal(false);
                    }}
                />
            )}
            {showMorgiPackagesModal && (
                <MorgisPackagesModal
                    onClose={() => {
                        setShowMorgiPackagesModal(false);
                    }}
                    rookieId={rookie?.id}
                    rookieName={rookie?.full_name}
                />
            )}
            {showMorgisCheckoutModal && (
                <MorgisCheckoutModal
                    onClose={() => {
                        setShowMorgisCheckoutModal(false);
                    }}
                />
            )}
            {showShareModal && (
                <ShareModal
                    title={t("gift_rookie.share_with_friends")}
                    onClose={() => {
                        setShowShareModal(false);
                    }}
                    url={`${parsedUrl.origin}${parsedUrl.pathname}?${PARAM_SHARE}=true`}
                    message={t("share_messages.leader_sharing", {
                        context: rookieGender,
                    })}
                />
            )}
        </>
    );
};

export default RookieProfile;

RookieProfile.serverFetch = fetchComponentData;

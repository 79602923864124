import React from "react";
import { createUseStyles } from "react-jss";
import cx from "classnames";

const useStyles = createUseStyles({
    rootStyle: {
        "z-index": 14,
        width: (props) => props.size,
        height: "auto",
        animation: "$rotate 2s linear infinite",
        "& circle": {
            animation: "$dash 1.5s ease-in-out infinite",
        },
    },
    "@keyframes rotate": {
        "100%": {
            transform: "rotate(360deg)",
        },
    },
    "@keyframes dash": {
        "0%": {
            strokeDasharray: "1, 150",
            strokeDashoffset: "0",
        },
        "50%": {
            strokeDasharray: "90, 150",
            strokeDashoffset: "-35",
        },
        "100%": {
            strokeDasharray: "90, 150",
            strokeDashoffset: "-124",
        },
    },

    centerSpin: {
        position: ({ overlayFullscreen }) =>
            overlayFullscreen ? "fixed" : "absolute",
        left: "50%",
        "margin-left": ({ size }) => -size / 2,
        top: "50%",
        "margin-top": ({ size }) => -size / 2,
    },

    overlayBlur: {
        "z-index": 13,
        position: ({ overlayFullscreen }) =>
            overlayFullscreen ? "fixed" : "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        "background-color": ({ overlayBg }) =>
            overlayBg ? overlayBg : "rgba(255, 255, 255, 0.5)",
    },
});

const Spinner = ({
    className,
    size = 50,
    color = "#333",
    strokeLineCap = "square",
    overlay = true,
    overlayFullscreen = false,
    center = true,
    overlayBg,
    ...props
}) => {
    const classes = useStyles({ size, overlayFullscreen, overlayBg });

    return (
        <>
            {overlay && <div className={classes.overlayBlur} />}
            <svg
                className={cx(
                    classes.rootStyle,
                    center && classes.centerSpin,
                    className
                )}
                viewBox="0 0 50 50"
                {...props}
            >
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    stroke={color}
                    strokeWidth="3"
                    strokeLinecap={strokeLineCap}
                />
            </svg>
        </>
    );
};

export default Spinner;

import React from 'react';
import { Switch } from 'react-router-dom';
import routes from "./routes";
import './index.css';
import { MixpanelProvider } from './utilities/mixpanel/MixpanelProvider';

const App = () => {

  return (
    <MixpanelProvider>
      <Switch>
        {routes.map(({ routeType: RouteComponent, ...rest }, i) => (
          <RouteComponent
            {...rest}
            key={i}
          />
        ))}
      </Switch>
    </MixpanelProvider>
  )
};

export default App;

import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import { MURAL_TYPES } from "./MuralItem";
import Button from "../../../components/Button";
import { BigLockIcon, PlayButton } from "../../../assets/icons/icons";
import { useViewportSize } from "../../../hooks/useViewportSize";

const useStyles = createUseStyles((theme) => ({
    unpaidContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        inset: 0, // offset → inset у CSS
        zIndex: 1,
        width: "100%",
        height: "100%",
    },
    unpaidContentWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing * 1.75,
    },
    button: {
        "& span": {
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    },
    mediaWrapper: ({ needToBlur }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        position: "relative",
        objectFit: "cover",
        overflow: "hidden",
        "& > video": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
        "& > img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            filter: needToBlur ? "blur(7px)" : "none",
        },
    }),
    playButtonWrapper: {
        position: "absolute",
        inset: 0, // offset → inset
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& svg": {
            borderRadius: 8,
            padding: theme.spacing * 1.5,
            border: "2px solid white",
        },
    },
}));

const MuralMediaItem = ({ mural, onUnlockClick, hostName, defaultUrl }) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const { vw } = useViewportSize();
    const isMobile = vw * 100 <= 768;
    const isMediaPackage = mural?.mural_type === MURAL_TYPES.MEDIA_PACKAGES;
    const isPaid =
        isMediaPackage && mural?.media_packages_contents
            ? !!mural?.media_packages_contents[0]?.url
            : !!mural?.url;
    const url = mural?.url ?? mural?.blurred_url;
    const classes = useStyles({ needToBlur: !url });

    return (
        <>
            {!isPaid && (
                <div className={classes.unpaidContent}>
                    <div className={classes.unpaidContentWrapper}>
                        <BigLockIcon />
                        <Button
                            onClick={onUnlockClick}
                            data-primary
                            maxWidth={isMobile ? "330px" : "580px"}
                            width={isMobile ? "330px" : "580px"}
                            className={classes.button}
                        >
                            {t("rookie_profile_page.subscribe_for_content", {
                                NAME: hostName,
                            })}
                        </Button>
                    </div>
                </div>
            )}
            {mural?.mural_type === MURAL_TYPES.PHOTOS || !mural?.url ? (
                <div className={classes.mediaWrapper}>
                    <img src={url ?? defaultUrl} alt="image" />
                </div>
            ) : (
                <div className={classes.mediaWrapper}>
                    {isPaid && (
                        <div className={classes.playButtonWrapper}>
                            <PlayButton width={70} height={70} />
                        </div>
                    )}
                    <video>
                        <source src={`${url ?? defaultUrl}#t=0.001`} />
                    </video>
                </div>
            )}
        </>
    );
};

export default MuralMediaItem;

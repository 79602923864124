import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import { createUseStyles } from "react-jss";
import { GOAL_STATUSES } from "../../../utilities/constants/goalTypes";
import { GoalsGradient } from "../../../assets/icons/icons";
import GoalCard from "../../../components/GoalCard";

const useStyles = createUseStyles((theme) => ({
    goalSection: {
        padding: `0px ${theme.spacing * 2}px`,
        [theme.mUp]: {
            padding: `0px`,
        },
    },
    achievementsTitle: {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "28px",
        margin: "16px 0 16px 0",
        borderBottom: "1px solid #7F829D33",
        paddingBottom: theme.spacing * 2,
        [theme.mUp]: {
            fontSize: "24px",
        },
    },
}));

const RookieGoalSection = ({ rookieProfile }) => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });

    const {
        type_attributes: { goals },
        full_name,
    } = rookieProfile;

    const activeRookieGoals = goals.filter(
        (goal) => goal?.status === GOAL_STATUSES.active
    );

    return (
        <div className={classes.goalSection}>
            <h4 className={classes.achievementsTitle}>
                {t("rookie_profile_page.goal_section.title")}
            </h4>
            {!(activeRookieGoals.length === 0) ? (
                <div>
                    {activeRookieGoals.map((goal) => {
                        return <GoalCard key={goal?.id} goal={goal} />;
                    })}
                </div>
            ) : (
                <div>
                    {t("goal_card.has_not_active_goals", {
                        USER: full_name,
                    })}
                </div>
            )}
        </div>
    );
};

export default RookieGoalSection;

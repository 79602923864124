import React, { useEffect, useRef } from 'react';
import PublicLayout from '../containers/PublicLayout.js';
import { Route } from 'react-router-dom';
import { toggleThemeMode } from "../store/settingSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { KNOWN_COOKIES } from "../utilities/constants/user";
import { getAppLink } from "../utilities/utils";
import { setRefreshCarousel } from "../store/carouselSlice";
import { useTrackEvent } from '../utilities/mixpanel/hooks/useTrackEvent';
import { MIXPANEL_TYPES } from '../utilities/constants/mixpanel.js';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';

const PublicRoute = ({ isDarkTheme = false, title, meta, fullWidth = false, lockCarousel, ...rest }) => {
    const dispatch = useDispatch();
    const isDarkThemeRef = useRef();
    const { track } = useTrackEvent()
    const { search } = useLocation();
    useEffect(
        () => {
            if (typeof window !== `undefined` && Cookies.get(KNOWN_COOKIES.authCookie)) {
                window.location.href = getAppLink(rest.location.pathname)
            }
            dispatch(setRefreshCarousel(!lockCarousel))
            console.log(location);
        },
        []
    )
    useEffect(() => {
        const params = rest.computedMatch.params

        track(MIXPANEL_TYPES.PAGE_VIEW_ANON, {
            url: rest.computedMatch.url,
            utm: !!search ? search : undefined,
            page_name: rest.component.name,
            host_name: params.username
        }, 0)

    }, [rest.component.name])

    if (isDarkThemeRef.current !== isDarkTheme) {
        dispatch(toggleThemeMode({ themeMode: isDarkTheme ? 'dark' : 'light' }));
        isDarkThemeRef.current = isDarkTheme;
    }

    return <PublicLayout title={title} meta={meta} fullWidth={fullWidth}>
        <Route {...rest} />
    </PublicLayout>
}

export default PublicRoute;

import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import MuralMediaItem from "./MuralMediaItem";
import MuralPackageItem from "./MuralPackageItem";
import MuralSurpriseItem from "./MuralSurpriseItem";
import Portal from "../../../components/Portal";
import LightBox from "../../../components/LightBox";
import { getLocalDate } from "../../../utilities/helpers";
import Button from "../../../components/Button";

const useStyles = createUseStyles((theme) => ({
    contentWrapper: {
        position: "relative",
        width: "100%",
        cursor: ({ isPaid }) => (isPaid ? "pointer" : "default"),
        height: 343,
        flexShrink: 0,
        boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)",
        borderRadius: 46,
        overflow: "hidden",
        margin: `${theme.spacing * 2}px 0px`,
        [theme.mUp]: {
            height: 540,
        },

        "& img, & video, & > :after": {
            borderRadius: 46,
            overflow: "hidden",
        },
    },
    shadow: ({ isPaid }) => ({
        position: "relative",
        width: "100%",
        height: "100%",
        "&::after": !isPaid
            ? {
                  content: '""',
                  position: "absolute",
                  inset: 0,
                  background: "rgba(199, 169, 214, 0.77)",
              }
            : undefined,
    }),
    fullNameContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing * 2,

        "& > div": {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing / 2,

            "& > p": {
                margin: "0px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
    },
    avatar: {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
    },
    date: {
        fontSize: "14px",
        color: "#6d6d6d",
        fontWeight: 600,
        letterSpacing: "-0.3px",
    },
    button: {
        marginBottom: theme.spacing * 2,
        "& span": {
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    },
}));

export const MURAL_TYPES = {
    SURPRISES: "surprises",
    MEDIA_PACKAGES: "media_packages",
    VIDEOS: "videos",
    PHOTOS: "photos",
};

const MuralItem = ({
    mural,
    onUnlockClickSignUp,
    hostName,
    defaultUrl,
    avatar,
    onUnlockClickOpenSubscription,
    onUnlockClickOpenMorgiPackages,
}) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });

    const isMediaPackage = mural?.mural_type === MURAL_TYPES.MEDIA_PACKAGES;
    const isPaid =
        isMediaPackage && mural?.media_packages_contents
            ? !!mural?.media_packages_contents[0]?.url
            : !!mural?.url;
    const classes = useStyles({ isPaid });

    const formattedDate = getLocalDate(mural?.created_at, "MMM D, YYYY");

    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    const correctItem = {
        [MURAL_TYPES.PHOTOS]: (
            <MuralMediaItem
                mural={mural}
                onUnlockClick={onUnlockClickSignUp}
                hostName={hostName}
                defaultUrl={defaultUrl}
            />
        ),
        [MURAL_TYPES.VIDEOS]: (
            <MuralMediaItem
                mural={mural}
                onUnlockClick={onUnlockClickSignUp}
                hostName={hostName}
            />
        ),
        [MURAL_TYPES.SURPRISES]: (
            <MuralSurpriseItem
                mural={mural}
                onUnlockClick={onUnlockClickOpenSubscription}
                hostName={hostName}
            />
        ),
        [MURAL_TYPES.MEDIA_PACKAGES]: (
            <MuralPackageItem mural={mural} hostName={hostName} />
        ),
    };

    return (
        <>
            {!!mural ? (
                <>
                    <div className={classes.fullNameContainer}>
                        <img
                            src={avatar}
                            alt="avatar"
                            className={classes.avatar}
                        />
                        <div>
                            <p>{hostName}</p>
                            <span className={classes.date}>
                                {formattedDate}
                            </span>
                        </div>
                    </div>
                    <div
                        className={classes.contentWrapper}
                        onClick={() => {
                            isPaid && setIsPreviewOpen(true);
                        }}
                    >
                        <div className={classes.shadow}>
                            {correctItem[mural?.mural_type]}
                        </div>
                    </div>
                    {!isPaid &&
                        mural?.mural_type === MURAL_TYPES.MEDIA_PACKAGES && (
                            <Button
                                onClick={onUnlockClickOpenMorgiPackages}
                                data-primary
                                className={classes.button}
                                maxWidth="600px"
                            >
                                {t("rookie_profile_page.unlock_media_package", {
                                    NAME: hostName,
                                })}
                            </Button>
                        )}
                </>
            ) : (
                <></>
            )}
            {isPreviewOpen && (
                <Portal>
                    <LightBox
                        media={[
                            {
                                type:
                                    mural?.mural_type === MURAL_TYPES.VIDEOS
                                        ? "video"
                                        : "image",
                                url: mural?.url,
                            },
                        ]}
                        plyrOptions={{
                            controls: [
                                "play",
                                "progress",
                                "current-time",
                                "mute",
                                "volume",
                                "settings",
                                "fullscreen",
                            ],
                        }}
                        autoplay
                        onClose={() => setIsPreviewOpen(false)}
                    />
                </Portal>
            )}
        </>
    );
};

export default MuralItem;

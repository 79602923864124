import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { getAvailableNamespaces } from "../../translations";
import MorgisPackagesModal from "../rookieProfilePage/MorgisPackagesModal/MorgisPackagesModal";
import SubscriptionsPlansModal from "../rookieProfilePage/SubscriptionsPlansModal/SubscriptionsPlansModal";
import { MembershipPlans } from "../rookieProfilePage/ChooseSupportModal/components/MembersipPlans/MembershipPlans";
import { MorgisPackage } from "../rookieProfilePage/ChooseSupportModal/components/MorgisPackage/MorgisPackage";
import { fetchComponentData } from "../rookieProfilePage/RookieProfile";
import { useParams } from "react-router-dom";
import i18n from "../../i18n";
import customAxios from "../../utilities/axios";
import Spinner from "../../components/Spinner";
import { useViewportSize } from "../../hooks/useViewportSize";
import { MIXPANEL_TYPES } from "../../utilities/constants/mixpanel";
import { useTrackEvent } from "../../utilities/mixpanel/hooks/useTrackEvent";

const useStyles = createUseStyles((theme) => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
    },
    title: {
        marginTop: "32px",
        marginBottom: "16px",
        fontFamily: "Inter",
        fontWeight: "500",
        fontSize: "36px",
        letterSpacing: "-0.02em",
        textAlign: "center",
    },
    subTitle: {
        fontFamily: "Inter",
        fontSize: "18px",
        letterSpacing: "-0.02em",
        textAlign: "center",
    },
    container: {
        maxWidth: "600px",
        padding: "0 8px 8px 8px",
        height: "100%",
    },
    "@media (max-width: 768px)": {
        title: {
            fontSize: "24px",
            fontWeight: "600",
        },
        subTitle: {
            fontSize: "16px",
        },
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: ({ vh }) => `calc(${vh}px * 100 - 64px)`,
        },
    },
}));

export const Checkout = () => {
    const { vh } = useViewportSize();
    const classes = useStyles({ vh });
    const { track } = useTrackEvent()
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });

    const lang = i18n.language.split("_")[0];
    const { username: rookieUserName } = useParams();
    const [rookie, setRookie] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [packagesList, setPackagesList] = useState();
    const [showSubscriptionPlansModal, setShowSubscriptionPlansModal] =
        useState(false);
    const [showMorgiPackagesModal, setShowMorgiPackagesModal] = useState(false);

    const fetchRookie = async () => {
        try {
            setIsLoading(true);
            const data = await fetchComponentData({ param: rookieUserName });
            setRookie(data);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchRookie();
    }, [lang]);

    useEffect(() => {
        const fetchPackages = async () => {
            if (rookie?.id) {
                const { data } = await customAxios.get(
                    `/subscriptions/rookies/${rookie.id}/packages`
                );
                const filteredData = data.filter((pack) => !!pack.group)
                    .sort((a, b) => Number(Boolean(b.discount)) - Number(Boolean(a.discount)));
                setPackagesList(filteredData);
            }
        };
        fetchPackages();
    }, [rookie?.id]);

    return (
        <div className={classes.root}>
            {!packagesList || isLoading ? (
                <Spinner />
            ) : (
                <div className={classes.container}>
                    <div>
                        <div className={classes.title}>
                            {t(
                                "rookie_profile_page.choose_support_modal.title"
                            )}
                        </div>
                        <div className={classes.subTitle}>
                            {t(
                                "rookie_profile_page.choose_support_modal.sub_title",
                                { ROOKIE_NAME: rookieUserName }
                            )}
                        </div>
                    </div>
                    <MembershipPlans
                        inModal={false}
                        content={packagesList?.[0]}
                        rookieName={rookie?.full_name}
                        onSubmit={() => {
                            track(MIXPANEL_TYPES.CTA_ANON_PAGE, {
                                cta: "see all plans",
                                host_name: rookieUserName,
                                url: location.pathname,
                                utm: !!location?.search ? location?.search : undefined,
                                host_distinct_id: rookie?.id
                            })
                            setShowSubscriptionPlansModal(true)
                        }}
                    />
                    <MorgisPackage
                        content={packagesList?.[0]}
                        onSubmit={() => setShowMorgiPackagesModal(true)}
                    />
                </div>
            )}
            {showSubscriptionPlansModal && (
                <SubscriptionsPlansModal
                    rookieId={rookie?.id}
                    hostName={rookie?.full_name}
                    onClose={() => setShowSubscriptionPlansModal(false)}
                />
            )}
            {showMorgiPackagesModal && (
                <MorgisPackagesModal
                    onClose={() => setShowMorgiPackagesModal(false)}
                    rookieId={rookie?.id}
                    rookieName={rookie?.full_name}
                />
            )}
        </div>
    );
};

export default Checkout;

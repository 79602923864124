import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Button from "../../../components/Button";
import { useViewportSize } from "../../../hooks/useViewportSize";
import { getAvailableNamespaces } from "../../../translations";
import { PARAM_USER_GROUP } from "../../../utilities/constants";
import { APP_ROUTE_SIGNUP_ROOKIE_SOCIAL } from "../../../utilities/constants/appRouteNames";
import { getUserGroup, ROOKIE_ROLES } from "../../../utilities/constants/user";
import {
    getAppLink,
    redirectToExternalLink,
    remapURLSearchParams,
} from "../../../utilities/utils";

const useStyles = createUseStyles({
    container: {
        maxWidth: "100%",
        width: "100%",
        height: "calc(100vh - 112px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
    },
    image: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        transition: "opacity 1s ease-in-out",
        opacity: 0,
        zIndex: 0,
    },
    imageVisible: {
        opacity: 1,
        zIndex: 1,
    },
    content: {
        position: "relative",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        maxWidth: 460,
        width: "100%",
        color: "#FFFFFF",
        marginRight: "30vw",
        zIndex: 2, // Щоб текст був над зображенням
        "& h1": {
            lineHeight: "57.18px",
            letterSpacing: "0.1em",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
            margin: 0,
        },
    },
    description: {
        fontWeight: 300,
        fontSize: "clamp(36px, 5vw, 47px)",
    },
    expertise: {
        fontSize: "clamp(38px, 3vw, 78px)",
        fontWeight: 700,
        textTransform: "uppercase",
        color: "#8649D1",
    },
    CTA_wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "12px",
    },
    CTA: {
        fontSize: "clamp(18px, 5vw, 24px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: "unset",
        border: "none !important",
    },
    "@media (max-width: 576px)": {
        container: {
            height: "calc(100vh - 64px)",
            alignItems: "flex-end",
            padding: [24, 8],
        },
        content: {
            maxWidth: 320,
            paddingBottom: "70px",
        },
        CTA: {
            width: "250px",
        },
    },
});

const mobileWidth = 576 / 100;

const BuildCommunitySection = ({ role = "main" }) => {
    const classes = useStyles();
    const { vw } = useViewportSize();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const [isMobile, setIsMobile] = useState(vw <= mobileWidth);
    const [currentSlide, setCurrentSlide] = useState(0);

    const expertiseMap = {
        main: ["attraction", "expertise", "influence", "skills"],
        [ROOKIE_ROLES.ARTISTS]: ["groove", "art", "creativity", "influence"],
        [ROOKIE_ROLES.ATHLETE]: [
            "discipline",
            "dedication",
            "endurance",
            "tenacity",
        ],
        [ROOKIE_ROLES.ENTREPRENEURS]: [
            "courage",
            "expertise",
            "experience",
            "vision",
        ],
        [ROOKIE_ROLES.INFLUENCER]: [
            "influence",
            "authenticity",
            "innovation",
            "connection",
        ],
        [ROOKIE_ROLES.LICENSED]: [
            "professionalism",
            "knowledge",
            "dedication",
            "excellence",
        ],
        [ROOKIE_ROLES.MODELS]: ["beauty", "fashion", "style", "authenticity"],
        [ROOKIE_ROLES.TEACHERS]: [
            "knowledge",
            "experience",
            "wisdom",
            "expertise",
        ],
    }[role];

    useEffect(() => {
        setIsMobile(vw <= mobileWidth);
    }, [vw]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prev) =>
                prev + 1 < expertiseMap.length ? prev + 1 : 0
            );
        }, 5000);
        return () => clearInterval(timer);
    }, [expertiseMap]);

    const getImagePath = (name) =>
        require(`../assets/buildCommunitySection/${role}/${name}${
            isMobile ? "_mobile" : ""
        }.jpg`);

    return (
        <section className={classes.container}>
            {/* Зображення */}
            {expertiseMap.map((name, index) => (
                <img
                    key={name}
                    src={getImagePath(name)}
                    alt={name}
                    className={`${classes.image} ${
                        index === currentSlide ? classes.imageVisible : ""
                    }`}
                />
            ))}

            {/* Текстовий контент */}
            <div className={classes.content}>
                <h1 className={classes.description}>
                    <Trans
                        t={t}
                        i18nKey={
                            "welcomeRookiePage.build_community_section.description"
                        }
                    />
                </h1>
                <h1 className={classes.expertise}>
                    {t(
                        `welcomeRookiePage.build_community_section.expertise.${expertiseMap[currentSlide]}`
                    )}
                </h1>
                <div className={classes.CTA_wrapper}>
                    <Button
                        data-primary
                        className={classes.CTA}
                        onClick={() =>
                            redirectToExternalLink(
                                getAppLink(APP_ROUTE_SIGNUP_ROOKIE_SOCIAL, [
                                    ...remapURLSearchParams(location.search),
                                    {
                                        name: PARAM_USER_GROUP,
                                        value: getUserGroup(),
                                    },
                                ])
                            )
                        }
                    >
                        {t("welcomeRookiePage.build_community_section.CTA")}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default BuildCommunitySection;

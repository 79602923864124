import React from "react";
import { createUseStyles } from "react-jss";
import heroSectionBackground from "../../../assets/images/welcomePage/HeroSectionBackground.jpg";
import AnonymousTourCTA from "../../anonPage/AnonymousTourCTA";
import { getAvailableNamespaces } from "../../../translations";
import { Trans, useTranslation } from "react-i18next";

const useStyles = createUseStyles({
    heroSection: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        overflow: "hidden",
        color: "#fff",
        maxWidth: "716px",
        height: "calc(100vh - 111px)",
        minHeight: "530px",
        width: "100%",
        padding: "0 29px",
        gap: "24px",
        "& h1": {
            margin: 0,
            color: "#8649D1",
            textAlign: "center",
            textShadow: "0px 0px 2px rgba(0, 0, 0, 0.40)",
            fontFamily: "Inter",
            fontSize: "48px",
            fontWeight: "900",
            lineHeight: "100%",
            letterSpacing: "2.4px",
            textTransform: "capitalize",
            zIndex: 1,
            transition: "all 0.5s",
        },
        "& p": {
            margin: 0,
            maxWidth: "502px",
            width: "100%",
            color: "rgba(0, 0, 0, 0.90)",
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "130%",
            textTransform: "capitalize",
            zIndex: 1,
            marginBottom: "32px",
            transition: "all 0.5s",
        },
        "& button": {
            zIndex: 1,
            backgroundColor: "#FFF",
            transition: "all 0.5s",
            "&:hover": {
                backgroundColor: "transparent",
            },
        },
    },
    background: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        transition: "all 0.5s",
        background: ({ backgroundImage }) =>
            `radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.50) 0%,  #FFF 100%), url(${backgroundImage}) no-repeat center center / cover`,
    },
    "@media (max-width: 1024px)": {
        heroSection: {
            "& h1": {
                fontSize: "32px",
                letterSpacing: "1.6px",
            },
            "& p": {
                fontSize: "16px",
            },
        },
        background: {
            background: ({ backgroundImage }) =>
                `radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.70) 50%,  #FFF 100%), url(${backgroundImage}) no-repeat center center / cover`,
        },
    },
});

const getImagePath = (language) =>
    require(`../../../assets/images/welcomePage/${language}/HeroSectionBackground.jpg`);

const HeroSection = () => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const {
        i18n: { language },
    } = useTranslation();
    const backgroundImage = getImagePath(language);
    const classes = useStyles({ backgroundImage });

    return (
        <div className={classes.heroSection}>
            <div className={classes.background} />
            <h1>
                <Trans t={t} i18nKey="hero_section.headline" />
            </h1>
            <p>
                <Trans t={t} i18nKey="hero_section.description" />
            </p>
            <AnonymousTourCTA />
        </div>
    );
};

export default HeroSection;

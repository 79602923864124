import React, { Fragment, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import Spinner from "../../../components/Spinner";
import customAxios from "../../../utilities/axios";
import MuralItem, { MURAL_TYPES } from "./MuralItem";
import HostProfileTaxiSection from "./HostProfileTaxiSection";

const useStyles = createUseStyles((theme) => ({
    hostProfileMuralList: {
        display: "flex",
        flexDirection: "column",
        padding: `${theme.spacing * 3}px ${theme.spacing * 2}px`,
        flex: 1,
        [theme.mUp]: {
            padding: `${theme.spacing * 3}px 0`,
        },
    },
    hostProfileMuralListHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    hostProfileMuralListHeaderTitle: {
        margin: 0,
        color: "#00063d",
        textAlign: "center",
        fontWeight: 700,
        lineHeight: "140%", // 36.4px
        fontSize: "20px",
        borderBottom: "1px solid #7F829D33",
        paddingBottom: theme.spacing * 2,
        width: "100%",
        textAlign: "start",
        [theme.mUp]: {
            fontSize: "24px",
        },
    },
    infoIcon: {
        cursor: "pointer",
        fill: "transparent",
    },
    filterTabs: {
        display: "flex",
        gap: theme.spacing * 2,
        padding: `${theme.spacing * 3}px 0`,
    },
    filterTab: {
        position: "relative",
        flex: 1,
        textAlign: "center",
        color: "rgba(0, 6, 61, 0.6)",
        fontWeight: 700,
        lineHeight: "140%", // 25.2px
        cursor: "pointer",
        transition: "color 1s",
        fontSize: "18px",
        [theme.m]: {
            fontSize: "14px",
        },
    },
    selected: {
        color: "#00063d",
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: "-4px",
            left: 0,
            width: "100%",
            height: "2px",
            background: "#8649d1",
        },
    },
    spinnerWrapper: {
        height: 360,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        marginTop: "80px",
        [theme.mUp]: {
            height: 560,
        },
    },
}));

const muralTypes = [undefined, "surprises", "media_packages"];

const RookieMural = ({
    rookie,
    onSignUpHandlerRedirect,
    openSubscriptionModal,
    openMorgiPackagesModal,
}) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const classes = useStyles({});

    const limit = 7;
    const [selectedTab, setSelectedTab] = useState(0);
    const [rookiePosts, setRookiePosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);

    const surprisesCount = rookie?.type_attributes?.surprises_count;
    const mediaPackagesCount = rookie?.type_attributes?.media_packages_count;

    const tabs = [
        surprisesCount || mediaPackagesCount
            ? { id: 0, title: t("common:see_all") }
            : undefined,
        surprisesCount
            ? {
                  id: 1,
                  title: t("rookie_profile_page.surprises_tab", {
                      COUNTER: surprisesCount,
                  }),
              }
            : undefined,
        mediaPackagesCount
            ? {
                  id: 2,
                  title: t("rookie_profile_page.packages_tab", {
                      COUNTER: mediaPackagesCount,
                  }),
              }
            : undefined,
    ].filter((tab) => Boolean(tab));

    const getRookiePosts = async ({ hostID, page, limit, mural_type }) => {
        try {
            setIsLoading(true);
            const { data } = await customAxios.get(
                `/public/rookies/${hostID}/mural`,
                {
                    params: {
                        page,
                        limit,
                        mural_type,
                    },
                }
            );
            setRookiePosts(data.data);
        } catch (error) {
            console.error("Error fetching rookie posts: ", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getRookiePosts({
            hostID: rookie.id,
            page,
            limit,
            mural_type: muralTypes[selectedTab],
        });
    }, [page, selectedTab, rookie.id]);

    const defaultUrlVideo = rookiePosts.find(
        (item) => item.mural_type === MURAL_TYPES.VIDEOS && !!item.url
    )?.url;
    const defaultUrlImage = rookiePosts.find(
        (item) => item.mural_type === MURAL_TYPES.PHOTOS && !!item.url
    )?.url;

    const wallInserts = [
        {
            index: 1,
            content: rookie?.type_attributes?.featured_rookies?.length ? (
                <HostProfileTaxiSection
                    featuredRookie={rookie.type_attributes.featured_rookies}
                />
            ) : null,
        },
    ];

    return (
        <div className={classes.hostProfileMuralList}>
            {!!tabs.length && (
                <div className={classes.hostProfileMuralListHeader}>
                    <h3 className={classes.hostProfileMuralListHeaderTitle}>
                        {t("rookie_profile_page.mural")}
                    </h3>
                    {/* <InfoLightCircleIcon
                                fill="#00063D80"
                                width={isMobile ? 24 : 30}
                                height={isMobile ? 24 : 30}
                                onClick={() => {
                                    setOpenToolTipModal(true);
                                }}
                                className={classes.infoIcon}
                            /> */}
                </div>
            )}

            {!!tabs.length && (
                <div className={classes.filterTabs}>
                    {tabs.map((tab) => (
                        <div
                            key={tab.id}
                            className={`${classes.filterTab} ${
                                selectedTab === tab.id ? classes.selected : ""
                            }`}
                            onClick={() => {
                                setSelectedTab(tab.id);
                            }}
                        >
                            {tab.title}
                        </div>
                    ))}
                </div>
            )}

            {isLoading ? (
                <div className={classes.spinnerWrapper}>
                    <Spinner overlay={false} center={false} />
                </div>
            ) : (
                !!rookiePosts.length &&
                rookiePosts.map((post, idx) => {
                    const extraContent = wallInserts.find(
                        (item) => item.index === idx
                    );
                    return (
                        <Fragment key={post.id}>
                            <MuralItem
                                mural={post}
                                hostName={rookie.full_name}
                                defaultUrl={
                                    post.mural_type === MURAL_TYPES.VIDEOS
                                        ? defaultUrlVideo
                                        : defaultUrlImage
                                }
                                avatar={rookie.avatar?.url}
                                onUnlockClickSignUp={onSignUpHandlerRedirect}
                                onUnlockClickOpenSubscription={
                                    openSubscriptionModal
                                }
                                onUnlockClickOpenMorgiPackages={
                                    openMorgiPackagesModal
                                }
                            />
                            {extraContent?.content}
                        </Fragment>
                    );
                })
            )}
        </div>
    );
};

export default RookieMural;

import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Slider from "react-slick";
import testimonialsAndrea from "../../../assets/images/welcomePage/TestimonialsAndrea.png";
import testimonialsJennifer from "../../../assets/images/welcomePage/TestimonialsJennifer.png";
import testimonialsJeremy from "../../../assets/images/welcomePage/TestimonialsJeremy.png";

import { getAvailableNamespaces } from "../../../translations";

const useStyles = createUseStyles({
    testimonials: {
        maxWidth: "1072px",
        margin: "0 auto",
        padding: "0 16px",
        boxSizing: "border-box",
        overflow: "hidden",
        "& .slick-list": {
            overflow: "hidden",
        },
        "& .slick-slide": {
            display: "flex",
            justifyContent: "center",
            width: "330px !important",
            margin: "0 8px",
        },
        "& .slick-slide > div": {
            maxWidth: "100%",
            flex: "0 1 auto",
            boxSizing: "border-box",
        },
    },
    testimonialCard: {
        width: "100%",
        maxWidth: "330px",
        height: "264px",
        padding: "24px",
        borderRadius: "8px",
        backgroundColor: "#fff",
        border: "1px solid #DBDBDB",
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
        overflow: "hidden",
        "& > p": {
            color: "#4B4B4B",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "130%",
        },
    },
    testimonialCardHeader: {
        display: "grid",
        gridTemplateRows: "28px",
        gridTemplateColumns: "56px 1fr",
        gridTemplateAreas: '"avatar name" "avatar role"',
        gap: "0 8px",
        marginBottom: "16px",
        "& > img": {
            gridArea: "avatar",
            width: "56px",
            height: "56px",
            borderRadius: "100px",
            objectFit: "cover",
        },
        "& > h4": {
            textAlign: "start",
            gridArea: "name",
            margin: 0,
            color: "#232323",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "18px",
        },
        "& > p": {
            textAlign: "start",
            gridArea: "role",
            margin: 0,
            color: "#737373",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "18px",
        },
    },
    slider: {
        maxHeight: "300px",
    },
    "@media (max-width: 1024px)": {
        testimonials: {
            maxWidth: "768px",
        },
    },
    "@media (max-width: 768px)": {
        testimonials: {
            maxWidth: "375px",
        },
    },
});

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
            },
        },
    ],
};

const TestimonialsSection = () => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const classes = useStyles();

    const testTestimonials = [
        {
            fullName: t("testimonials_section.jeremy.name"),
            role: t("testimonials_section.jeremy.role"),
            avatar: testimonialsJeremy,
            testimonial: t("testimonials_section.jeremy.testimonial"),
        },
        {
            fullName: t("testimonials_section.andrea.name"),
            role: t("testimonials_section.andrea.role"),
            avatar: testimonialsAndrea,
            testimonial: t("testimonials_section.andrea.testimonial"),
        },
        {
            fullName: t("testimonials_section.jennifer.name"),
            role: t("testimonials_section.jennifer.role"),
            avatar: testimonialsJennifer,
            testimonial: t("testimonials_section.jennifer.testimonial"),
        },
    ];

    return (
        <section className={classes.testimonials}>
            <Slider {...settings} className={classes.slider}>
                {testTestimonials.map((user) => (
                    <div
                        key={user.fullName}
                        className={classes.testimonialCard}
                    >
                        <div className={classes.testimonialCardHeader}>
                            <img src={user.avatar} alt={user.fullName} />
                            <h4>{user.fullName}</h4>
                            <p>{user.role}</p>
                        </div>
                        <p>{user.testimonial}</p>
                    </div>
                ))}
            </Slider>
        </section>
    );
};

export default TestimonialsSection;

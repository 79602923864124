import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import { getAvailableNamespaces } from "../../../translations";
import { Chat } from "../../../assets/icons/icons";
import cx from "classnames";

const useStyles = createUseStyles((theme) => ({
    cta: {
        zIndex: 2,
        position: "sticky",
        left: 0,
        right: 0,
        bottom: 16,
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "1fr",
        gridColumnGap: theme.spacing,
        //width: "330px",
        margin: "0 auto",
        justifyItems: "center",
        "& a": {
            textDecoration: "none",
            width: "100%",
        },
        "& button": {
            padding: 16,
            lineHeight: 1.2,
        },
        [theme.lUp]: {
            gridTemplateRows: 80,
            gridColumnGap: theme.spacing * 2,
            maxWidth: 464,
            "& button": {
                padding: 14,
            },
            minHeight: 72,
        },
    },
    sideButtonLeft: {
        justifySelf: "end",
    },
    sideButtonRight: {
        justifySelf: "start",
    },
}));

const RookieCTABar = ({
    status,
    onGift,
    onSaveRookie,
    onStartChat,
    isSaved,
    currentGiftAmount,
    leaderId,
    rookieId,
    message = "common:gift_now",
    fontWeight = 700,
    className,
}) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const classes = useStyles();

    return (
        <div className={cx(classes.cta, className)}>
            <Button
                data-primary
                width={"100%"}
                //maxHeight={72}
                fontWeight={fontWeight}
                fontSize={20}
                onClick={onGift}
            >
                {message}
            </Button>
        </div>
    );
};

export default RookieCTABar;

import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Button from "../../../components/Button";
import { getAvailableNamespaces } from "../../../translations";
import { PARAM_USER_GROUP } from "../../../utilities/constants";
import { APP_ROUTE_SIGNUP_LEADER_SOCIAL } from "../../../utilities/constants/appRouteNames";
import { getUserGroup } from "../../../utilities/constants/user";
import {
    getAppLink,
    redirectToExternalLink,
    remapURLSearchParams,
} from "../../../utilities/utils";

const useStyles = createUseStyles({
    exploreSection: {
        textAlign: "center",
    },
    title: {
        fontSize: 32,
        fontWeight: 700,
        marginBottom: 20,
        textAlign: "left",
        marginLeft: "40px",
    },
    cardWrapper: {
        display: "flex",
        gap: 16,
        flexWrap: "wrap",
        marginBottom: 48,
        justifyContent: "center",
    },
    card: {
        maxWidth: 343,
        width: "100%",
        flex: "1 0 auto",
    },
    cardHeader: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        gap: 10,
        margin: "8px 4px",
    },
    avatar: {
        width: 40,
        height: 40,
        borderRadius: "50%",
    },
    name: {
        color: "#212651",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "700",
        letterSpacing: "-0.3px",
    },
    image: {
        width: "100%",
        objectFit: "cover",
        borderRadius: 16,
    },
    button: {
        margin: "0 auto",
        maxWidth: 319,
        width: "100%",
    },
    "@media (max-width: 768px)": {
        cardWrapper: {
            flexDirection: "column",
            alignItems: "center",
        },
        title: {
            textAlign: "center",
            marginLeft: "0px",
        },
    },
});

const ExploreMoreSection = () => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const data = [
        {
            avatar: require("../assets/exploreMoreSection/avatars/avatar1.png"),
            image: require("../assets/exploreMoreSection/content/content1.png"),
            name: "Raysa Souza",
        },
        {
            avatar: require("../assets/exploreMoreSection/avatars/avatar2.png"),
            image: require("../assets/exploreMoreSection/content/content2.png"),
            name: "Luna Ferraz",
        },
        {
            avatar: require("../assets/exploreMoreSection/avatars/avatar3.png"),
            image: require("../assets/exploreMoreSection/content/content3.png"),
            name: "Você Sabia",
        },
    ];
    const redirectToSignUp = () => {
        redirectToExternalLink(
            getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
                ...remapURLSearchParams(location.search),
                {
                    name: PARAM_USER_GROUP,
                    value: getUserGroup(),
                },
            ])
        );
    };
    return (
        <div className={classes.exploreSection}>
            <h2 className={classes.title}>{t("explore_more_section.title")}</h2>
            <div className={classes.cardWrapper}>
                {data.map((item, index) => (
                    <div key={index} className={classes.card}>
                        <div className={classes.cardHeader}>
                            <img
                                src={item.avatar}
                                alt={item.name}
                                className={classes.avatar}
                            />
                            <div className={classes.name}>{item.name}</div>
                        </div>
                        <img
                            src={item.image}
                            alt="Content"
                            className={classes.image}
                        />
                    </div>
                ))}
            </div>
            <a to="/more-content">
                <Button
                    data-primary
                    className={classes.button}
                    onClick={redirectToSignUp}
                >
                    {t("explore_more_section.CTA")}
                </Button>
            </a>
        </div>
    );
};

export default ExploreMoreSection;

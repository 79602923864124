export const getListToShow = (viewedList, userList) => {
    const listToShow = viewedList.reduce((acc, currentValue) => {
        const idUserList = userList.map(({ id }) => id);

        if (idUserList.find((item) => item === currentValue.id)) {
            const indexOfElement = idUserList.indexOf(currentValue.id);
            acc = [
                ...acc,
                {
                    ...currentValue,
                    index: indexOfElement,
                    color: "#8649D1",
                },
            ];
            function compareNumbers(a, b) {
                return a.index - b.index;
            }
            acc.sort(compareNumbers);
        } else {
            acc = [
                ...acc,
                {
                    ...currentValue,
                    index: 10 + acc.length, //We have max 5 topics so 10 > 5
                    color: "#00063D",
                },
            ];
        }
        return acc;
    }, []);
    return listToShow;
};

import React from "react";
import { createUseStyles } from "react-jss";
import { GrandeGoalIcon, SnapGoalIcon, SpecialGoalIcon } from "../../assets/icons/icons";
import CoverMediaSlider from "./CoverMediaSlider";

const useStyles = createUseStyles((theme) => ({
  root: {},
  coverPhoto: {
    height: "240px",
    width: "100%",
    minWidth: "346px",
    margin: "20px auto",
    position: "relative",
    '&:before': {
      content: '""',
      background: ({ goalAvatar }) =>
      `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${goalAvatar}) no-repeat center/cover`,
      borderRadius: "10px",
      opacity: ({ computeIsGoalTimeEnded }) => computeIsGoalTimeEnded && "0.8",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      filter: ({ computeIsGoalTimeEnded }) => computeIsGoalTimeEnded && 'grayscale(100%)',
      [theme.mUp]: {
          borderRadius: '15px',
      },
    },
    [theme.mUp]: {
      width: "650px",
      borderRadius: "15px",
    },
    [theme.lUp]: {
      width: "868px",
    },
  },
  headerWrapper: {
    position: "absolute",
    padding: "16px",
    zIndex: 12
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    marginBottom: "10px",
  },
  titleWrapper: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "auto auto",
    gridColumnGap: theme.spacing,
  },
  title: {
    color: theme.colors.white,
    fontSize: "18px",
  },
}));
const CoverPhoto = ({ goal = {}, computeIsGoalTimeEnded, image }) => {
  const { name, media, type_id } =
    goal;
  const goalAvatar = !!media && !!media.length ? media[0].url : image;
  const classes = useStyles({
    computeIsGoalTimeEnded,
    goalAvatar,
  });

  const correctIcon = {
    1: <SnapGoalIcon />,
    2: <SpecialGoalIcon />,
    3: <GrandeGoalIcon />,
  };

  return (
    <div className={classes.coverPhoto}>
      <div className={classes.headerWrapper}>
        <div className={classes.header}>
          <div className={classes.titleWrapper}>
            {correctIcon[type_id]}
            <span className={classes.title}>{name}</span>
          </div>
        </div>
      </div>

      <CoverMediaSlider 
          media={goal?.media} 
          isGoalEnded={computeIsGoalTimeEnded}
      />
    </div>
  );
};

export default CoverPhoto;

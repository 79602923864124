import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

export const calculatePercentage = (goalProgress, goalMicromorgiAmount) => {
    if (
        (typeof goalProgress === "number" &&
            typeof goalMicromorgiAmount === "number") ||
        !!goalProgress
    ) {
        return Math.floor((+goalProgress / +goalMicromorgiAmount) * 100);
    } else {
        return 0;
    }
};

export const handleApiError = ({
    error,
    callbackOnFieldError,
    callbackOnGeneralError,
    prependNameSpace,
}) => {
    if (error?.response?.data) {
        Object.keys(error.response.data).forEach((errorFieldName) => {
            if (errorFieldName === "message") {
                const errorValue = error.response.data[errorFieldName];
                // Generic error
                callbackOnGeneralError(errorValue);
            } else {
                // Form field error
                const errorValue = prependNameSpace
                    ? `${prependNameSpace}:${error.response.data[errorFieldName]}`
                    : error.response.data[errorFieldName];
                callbackOnFieldError &&
                    callbackOnFieldError(errorFieldName, {
                        message: Array.isArray(errorValue)
                            ? errorValue.join(" ")
                            : errorValue,
                        type: "backend",
                    });
            }
        });
    }
};

export const replaceThousands = (amount, withoutK = false) => {
    let number;
    if (amount < 1000) {
        number = amount;
    } else if (withoutK) {
        const string = String(amount);

        number =
            string.slice(0, string.length - 3) +
            "," +
            string.slice(string.length - 3);
    } else {
        number = amount / 1000 + "k";
    }
    return number;
};

export const getMinMaxAmount = (array) => {
    if (!array.length) {
        return { min: null, max: null };
    }

    let min = array[0].amount;
    let max = array[0].amount;

    for (let i = 1; i < array.length; i++) {
        const amount = array[i].amount;
        if (amount < min) {
            min = amount;
        }
        if (amount > max) {
            max = amount;
        }
    }

    return {
        minToShow: replaceThousands(min, true),
        maxToShow: replaceThousands(max, true),
        minValue: min,
        maxValue: max,
    };
};

export const amountToShow = (amountsArray, defaultArrey, isForGoal = false) => {
    const { minToShow, maxToShow, minValue, maxValue } =
        getMinMaxAmount(amountsArray);

    let minAmount = minValue;
    let maxAmount = maxValue;

    // only for goal support
    if (isForGoal) {
        const maxAmountIndex = defaultArrey?.findIndex(
            (item) => item?.amount === maxValue
        );
        // check if the next amount is the bigest amount
        if (maxAmountIndex !== defaultArrey?.length - 1) {
            const nextMaxAmount = defaultArrey[maxAmountIndex + 1].amount;
            maxAmount = ` - ${nextMaxAmount - 1}`;
        } else {
            minAmount = minAmount === maxAmount ? "" : `${minToShow} - `;
            maxAmount = maxToShow + "+";
        }
    }
    if (!!minToShow) {
        const maxPart = amountsArray.length >= 2 ? ` - ${maxToShow}` : "";
        const stringToShow = isForGoal
            ? minAmount + maxAmount
            : minToShow + maxPart;
        return stringToShow;
    }
};

export const formatTimeDifference = (postDate, t, mode = "since") => {
    const targetTime = new Date(postDate);
    if (isNaN(targetTime.getTime())) {
        throw new Error("Invalid date format");
    }

    const now = new Date();
    const nowUtc = new Date(now.toISOString());
    const targetTimeUtc = new Date(targetTime.toISOString());

    const timeDifferenceInMs =
        mode === "since"
            ? nowUtc.getTime() - targetTimeUtc.getTime()
            : targetTimeUtc.getTime() - nowUtc.getTime();

    if (timeDifferenceInMs < 0 && mode === "since") {
        return t("common:inFuture");
    }

    const timeDifferenceInDays = Math.ceil(
        timeDifferenceInMs / (1000 * 60 * 60 * 24)
    );

    if (timeDifferenceInDays === 0) {
        const timeDifferenceInHours = Math.floor(
            timeDifferenceInMs / (1000 * 60 * 60)
        );
        if (timeDifferenceInHours === 0) {
            const timeDifferenceInMinutes = Math.floor(
                timeDifferenceInMs / (1000 * 60)
            );
            if (timeDifferenceInMinutes === 0) {
                const timeDifferenceInSeconds = Math.floor(
                    timeDifferenceInMs / 1000
                );
                return mode === "since"
                    ? `${timeDifferenceInSeconds} ${t("common:seconds")} ${t(
                          "common:ago"
                      )}`.toLowerCase()
                    : `${timeDifferenceInSeconds} ${t("common:seconds")} ${t(
                          "common:left"
                      )}`.toLowerCase();
            }
            return mode === "since"
                ? `${timeDifferenceInMinutes} ${t("common:minutes")} ${t(
                      "common:ago"
                  )}`.toLowerCase()
                : `${timeDifferenceInMinutes} ${t("common:minutes")} ${t(
                      "common:left"
                  )}`.toLowerCase();
        }
        return mode === "since"
            ? `${timeDifferenceInHours} ${t("common:hours")} ${t(
                  "common:ago"
              )}`.toLowerCase()
            : `${timeDifferenceInHours} ${t("common:hours")} ${t(
                  "common:left"
              )}`.toLowerCase();
    }

    if (timeDifferenceInDays <= 7) {
        return mode === "since"
            ? `${timeDifferenceInDays} ${t("common:days")} ${t(
                  "common:ago"
              )}`.toLowerCase()
            : `${timeDifferenceInDays} ${t("common:days")} ${t(
                  "common:left"
              )}`.toLowerCase();
    } else {
        const options = { month: "long", day: "2-digit", year: "numeric" };
        return targetTime.toLocaleDateString("en-US", options);
    }
};

export const formatDate = (date, formatType = GLOBAL_DATE_FORMAT) =>
    date ? dayjs(date).format(formatType) : "-";

export const getTimezone = () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    return dayjs.tz.guess();
};

export const getLocalDate = (date, formatType) => {
    const timeZone = getTimezone();
    const localDate = dayjs(date).tz(timeZone);

    if (formatType) {
        return formatDate(dayjs(localDate).format(), formatType);
    } else {
        return localDate.format();
    }
};

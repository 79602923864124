import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import resources from "./translations";
import { getAvailableNamespaces } from "./translations";

const customLanguageDetector = {
    name: "browserLanguage",
    lookup() {
        const found = [];
        if (typeof navigator !== "undefined") {
            if (navigator.language) {
                found.push(navigator.language);
            }
        }
        return found.length > 0 ? found : undefined;
    },
};
const languageDetector = new LanguageDetector();
languageDetector.addDetector(customLanguageDetector);

const i18nextDetectorOptions = {
    order: ["cookie", "localStorage", "browserLanguage"],
    caches: ["cookie", "localStorage"],
    lookupLocalStorage: "i18nextLng",
    lookupCookie: "i18nextLng",
    // only detect languages that are in the whitelist
    //whitelist: ['en_US', 'pt_BR', 'es_419'],
    checkWhitelist: false,
    convertDetectedLanguage: (lng) => lng.replace("-", "_"),
    cookieOptions: { path: "/", domain: ".morgis.com", secure: true },
};

const checkEmptyPostProcessor = {
    name: "checkEmpty",
    type: "postProcessor",

    process: (value, key, options) => {
        if (!value || value.trim() === "") {
            if (options.lng !== "en_US") {
                return i18n.t(key, { ...options, lng: "en_US" });
            }
        }
        return value;
    },
};

if (process && !process.release) {
    i18n.use(Backend)
        .use(initReactI18next)
        .use(languageDetector)
        .use(checkEmptyPostProcessor);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
    i18n.init({
        resources,
        ns: getAvailableNamespaces(),
        defaultNS: "common",
        fallbackLng: "pt_BR",
        supportedLngs: ["en_US", "pt_BR", "es_419"],
        debug: false,
        react: {
            useSuspense: false, //useSuspense: process && !process.release
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: i18nextDetectorOptions,
        postProcess: ["checkEmpty"],
        postProcessPassResolved: true,
    });
}

export default i18n;

import React, { createContext, useContext, useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { runtimeConfig } from "../../config";

const ORIGINAL_MIXPANEL_TOKEN = runtimeConfig.mixpanel_project_token;
const MixpanelContext = createContext(null);
export const MixpanelProvider = ({ children }) => {
    useEffect(() => {
        mixpanel.init(ORIGINAL_MIXPANEL_TOKEN, { debug: true });
    }, []);

    return (
        <MixpanelContext.Provider value={mixpanel}>
            {children}
        </MixpanelContext.Provider>
    );
};

export const useMixpanel = () => {
    return useContext(MixpanelContext);
};

import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../../translations";
import { HeartIcon, HeartIconFilled } from "../../../../assets/icons/icons";
import cx from "classnames";
import { getListToShow } from "./helpers";

const useStyles = createUseStyles((theme) => ({
    containerRow: {
        display: "flex",
        padding: `0 ${theme.spacing * 2}px`,
        "& > div": {
            flex: 1,
        },
        [theme.mUp]: {
            padding: 0,
        },
    },
    heartSection: {
        display: "flex",
        gap: theme.spacing,
        alignItems: "center",
        marginBottom: theme.spacing,
        "& span": {
            fontSize: "16px",
            letterSpacing: "-0.02em",
            display: "flex",
            alignItems: "center",
            color: "#212651",
            fontWeight: 700,
            [theme.mUp]: {
                fontSize: "22px",
            },
        },
    },
    heartButton: {
        cursor: "pointer",
    },
    informSection: {
        display: "flex",
        gap: theme.spacing * 2,
        flexDirection: "column",
    },
    informSectionRow: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing,
    },
    informSectionRowTitle: {
        margin: 0,
        color: "#212651",
        fontWeight: 700,
        fontSize: "16px",
        letterSpacing: "-0.02em",
        [theme.mUp]: {
            fontSize: "22px",
        },
    },
    informSectionRowContent: {
        fontSize: "14px",
        display: "flex",
        gap: theme.spacing,
        color: "rgba(44, 47, 73, 0.9)",
        fontWeight: 400,
        lineHeight: "140%", // 19.6px
        flexWrap: "wrap",
    },
    favoriteItem: {
        color: theme.black,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: `${theme.spacing / 2}px ${theme.spacing}px`,
        width: "min-content",
        minWidth: "min-content",
        borderRadius: "100px",
        border: "0.5px solid #f2f2f2",
        background: theme.white,
        boxShadow: "0px 1px 8px 0px rgba(26, 8, 105, 0.25)",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textWrap: "nowrap",
        cursor: "default",
        fontSize: "14px",
        [theme.mUp]: {
            fontSize: "20px",
            fontWeight: 500,
            letterSpacing: "-0.3px",
        },
    },
}));

const InformSectionRow = ({ title, content }) => {
    const classes = useStyles();

    return (
        <div className={cx(classes.informSection)}>
            {title && (
                <h3 className={classes.informSectionRowTitle}>{title}</h3>
            )}
            {content && (
                <div className={classes.informSectionRowContent}>{content}</div>
            )}
        </div>
    );
};

const FavoriteItem = ({ children, color }) => {
    const classes = useStyles({ color });

    return <div className={classes.favoriteItem}>{children}</div>;
};

const DescriptionTopicsSection = ({ rookie }) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const classes = useStyles({});
    const likesCounter = rookie?.type_attributes?.saved_count;
    const sizeOfHeart = 20;

    const {
        description,
        type_attributes: { path },
        chat_topics,
        favorite_activities,
    } = rookie;

    const hostsWish = path;

    const userFavChatTopics = [];
    const userFavActivities = [];
    const favChatTopicsListToShow = getListToShow(
        chat_topics,
        userFavChatTopics
    );
    const favActivitiesListToShow = getListToShow(
        favorite_activities,
        userFavActivities
    );

    return (
        <>
            <div className={cx(classes.containerRow, classes.heartSection)}>
                <span
                    className={classes.heartButton}
                    onClick={() => {
                        console.log("click heart");
                    }}
                >
                    <HeartIcon width={sizeOfHeart} height={sizeOfHeart} />
                </span>
                {likesCounter >= 100 && (
                    <span>
                        {likesCounter > 1000
                            ? (likesCounter / 1000).toFixed(3)
                            : likesCounter}
                    </span>
                )}
            </div>
            <div className={cx(classes.containerRow, classes.informSection)}>
                {hostsWish?.name || description ? (
                    <InformSectionRow
                        title={
                            hostsWish.name &&
                            t("rookie_profile_page.i_am", {
                                PROFESSION: hostsWish.name,
                            })
                        }
                        content={description}
                    />
                ) : null}
                <InformSectionRow
                    title={t("description_section.topics_title")}
                    content={favChatTopicsListToShow.map((chatTopic) => (
                        <FavoriteItem
                            key={chatTopic.id}
                            color={chatTopic.color}
                        >
                            {chatTopic.name}
                        </FavoriteItem>
                    ))}
                />
                <InformSectionRow
                    title={t("description_section.actions_title")}
                    content={favActivitiesListToShow.map((favoriteActivity) => (
                        <FavoriteItem
                            key={favoriteActivity.id}
                            color={favoriteActivity.color}
                        >
                            {favoriteActivity.name}
                        </FavoriteItem>
                    ))}
                />
            </div>
        </>
    );
};

export default DescriptionTopicsSection;

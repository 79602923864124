import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import { MURAL_TYPES } from "./MuralItem";
import { BigLockIcon } from "../../../assets/icons/icons";
import Button from "../../../components/Button";
import { useViewportSize } from "../../../hooks/useViewportSize";

const useStyles = createUseStyles((theme) => ({
    unpaidContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        inset: 0, // offset → inset у CSS
        zIndex: 1,
        width: "100%",
        height: "100%",
    },
    unpaidContentWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing * 1.75,
    },
    button: {
        "& span": {
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    },
}));

const MuralSurpriseItem = ({ mural, hostName, onUnlockClick }) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const { vw } = useViewportSize();
    const isMobile = vw * 100 <= 768;
    const isMediaPackage = mural?.mural_type === MURAL_TYPES.MEDIA_PACKAGES;
    const isPaid =
        isMediaPackage && mural?.media_packages_contents
            ? !!mural?.media_packages_contents[0]?.url
            : !!mural?.url;
    const classes = useStyles({});

    return (
        <>
            {!isPaid && (
                <>
                    <div className={classes.unpaidContent}>
                        <div className={classes.unpaidContentWrapper}>
                            <BigLockIcon />
                            <Button
                                onClick={onUnlockClick}
                                data-primary
                                maxWidth={isMobile ? "330px" : "580px"}
                                width={isMobile ? "330px" : "580px"}
                                className={classes.button}
                            >
                                {t("rookie_profile_page.open_surprises", {
                                    NAME: hostName,
                                })}
                            </Button>
                        </div>
                    </div>
                    <div className={classes.mediaWrapper}>
                        <img src={mural?.blurred_url} alt="image" />
                    </div>
                </>
            )}
        </>
    );
};

export default MuralSurpriseItem;

import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const useStyles = createUseStyles((theme) => ({
    hostProfileTaxiSectionContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing * 3,
        padding: `0px 0px ${theme.spacing * 2}px`,
        maxWidth: "100%",
        [theme.mUp]: {
            padding: `0px 0px ${theme.spacing * 3}px`,
        },
    },
    hostProfileTaxiSectionHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    hostProfileTaxiSectionHeaderTitle: {
        fontSize: "20px",
        color: theme.colors.darkPurple,
        textAlign: "center",
        fontWeight: 700,
        lineHeight: "140%", // 28px
        [theme.mUp]: {
            fontSize: "24px",
        },
    },
    hostProfileTaxiSectionList: {
        display: "flex",
        gap: theme.spacing,
        maxWidth: 343,
        overflowX: "auto",
        paddingBottom: theme.spacing,
        "&::-webkit-scrollbar": {
            height: 6,
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#ccc",
            borderRadius: 3,
        },
        [theme.mUp]: {
            maxWidth: 600,
        },
    },
    taxiUser: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing,
        borderRadius: 8,
        border: "1px solid rgba(134, 73, 209, 0.2)",
        background: "#fff",
        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.1)",
        justifyContent: "space-between",
        padding: theme.spacing,
        height: 135,
        width: 110,
        flexShrink: 0,
        [theme.mUp]: {
            padding: theme.spacing * 2,
            height: 159,
            width: 158,
        },
    },
    avatar: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
    },
    taxiUserFullName: {
        color: "#212651",
        textAlign: "center",
        fontWeight: 600,
        lineHeight: "130%", // 26px
        letterSpacing: "-0.3px",
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        margin: 0,
        [theme.mUp]: {
            fontSize: "20px",
        },
    },
    taxiUserCTA: {
        fontSize: "12px",
        padding: "4px 8px",
        color: "#8649d1",
        textAlign: "center",
        fontWeight: 500,
        textTransform: "capitalize",
        border: "1px solid #8649d1",
        borderRadius: "15px",
        background: "transparent",
        cursor: "pointer",
        [theme.mUp]: {
            fontSize: "18px",
        },
    },
}));

const HostProfileTaxiSection = ({ featuredRookie }) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const classes = useStyles({});
    const history = useHistory();

    return (
        <div className={classes.hostProfileTaxiSectionContainer}>
            <div className={classes.hostProfileTaxiSectionHeader}>
                <h3 className={classes.hostProfileTaxiSectionHeaderTitle}>
                    {t("rookie_profile_page.taxi_section.featured_hosts")}
                </h3>
            </div>
            <div className={classes.hostProfileTaxiSectionList}>
                {featuredRookie.map((user) => (
                    <div
                        key={user.id}
                        className={classes.taxiUser}
                        onClick={() => {
                            history.push(`/${user.username}`);
                        }}
                    >
                        <img
                            src={user.photos?.find((photo) => photo.main)?.url}
                            alt="avatar"
                            className={classes.avatar}
                        />
                        <h3 className={classes.taxiUserFullName}>
                            {user.full_name}
                        </h3>
                        <button className={classes.taxiUserCTA}>
                            {t("rookie_profile_page.taxi_section.see_profile")}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HostProfileTaxiSection;

import { createUseStyles } from "react-jss";
import { ClockIcon, MicromorgiIcon } from "../assets/icons/icons";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../translations";
import { strokeColor } from "../utilities/constants/goalTypes";
import {
    calculatePercentage,
    formatTimeDifference,
} from "../utilities/helpers";
import CircularProgress from "./CircularProgress";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTE_GOAL_PAGE } from "../utilities/constants";
import { useViewportSize } from "../hooks/useViewportSize";
import { useState } from "react";

const useStyles = createUseStyles((theme) => ({
    goalContainer: {
        borderRadius: 4,
        background: theme.white,
        boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
        cursor: "pointer",
    },
    goalPhoto: {
        height: 193,
        objectFit: "cover",
        width: "100%",
        [theme.mUp]: {
            height: 330,
        },
    },
    goalDescriptionBox: {
        display: "flex",
        flexDirection: "row",
        padding: `${theme.spacing}px ${theme.spacing * 3}px ${
            theme.spacing
        }px ${theme.spacing * 2}px`,
        [theme.mUp]: {
            fontSize: "20px",
        },
    },
    goalDescriptionColumn: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing / 2,
        justifyContent: "center",
        "& text": {
            fontSize: "12px",
        },
        [theme.mediaQueries.m]: {
            "& text": {
                fontSize: "18px",
            },
        },
    },
    fullWidth: {
        flex: 1,
    },
    goalDescriptionRow: {
        display: "flex",
        color: "#313663",
        gap: theme.spacing / 2,
        lineHeight: "140%",
        fontWeight: 400,
        alignItems: "center",
    },
    clockIcon: {
        "& path": {
            fill: "#6c6e80",
        },
    },
    restTime: {
        color: "#6c6e80",
    },
    collectedMoney: {
        color: "#05ce78",
    },

    // Progress bar
    percentageZero: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        width: 60,
        height: 60,
        borderRadius: "50%",
        border: `1px solid rgb(0,0,0, 0.2)`,
        fontWeight: 700,
    },
    progressClassName: {
        "&:after": {
            content: '""',
            position: "relative",
            display: "block",
            width: "2px",
            height: "6px",
            background: "#20BF55",
            borderRadius: "1px",
            top: "-100%",
            left: "50%",
        },
    },
    percentageClassName: {
        zIndex: "-1",
        left: "1px",
        bottom: "1px",
        fontWeight: 700,
        height: "95%",
        width: "95%",
        borderRadius: "50%",
        border: `1px solid ${theme.colors.lightGrey}`,
    },
    currentBalance: {
        display: "flex",
        gap: theme.spacing / 2,
        alignItems: "center",
        fontWeight: 600,
        color: "#6c6e80",
    },
    desiredBalance: {
        display: "flex",
        gap: theme.spacing / 2,
        alignItems: "center",
        fontWeight: 600,
        color: "#6c6e80",
    },
    balanceContainer: {
        display: "flex",
        color: "#313663",
        gap: theme.spacing / 2,
        lineHeight: "140%",
        fontWeight: 400,
        alignItems: "center",
        borderRadius: "100px",
        border: "2px solid #d7d7d7",
        width: "min-content",
        padding: `0 ${theme.spacing * 1.5}px`,
        "& > div:first-child": {
            borderRight: "2px solid #d7d7d7",
            paddingRight: theme.spacing,
        },
    },
    currentBalance: {
        display: "flex",
        gap: theme.spacing / 2,
        alignItems: "center",
        fontWeight: 600,
        color: "#6c6e80",
    },
    desiredBalance: {
        display: "flex",
        gap: theme.spacing / 2,
        alignItems: "center",
        fontWeight: 600,
        color: "#6c6e80",
    },
}));

const GoalCard = ({ goal, onClick }) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const classes = useStyles();
    const history = useHistory();

    const {
        name,
        target_amount,
        type_id,
        donations_count,
        media,
        status,
        slug,
        id,
        end_date,
    } = goal;

    const goalSize = type_id;
    const percentage = calculatePercentage(donations_count, target_amount);
    const goalMedia = !!media && !!media.length ? media[0] : "";
    const isPhoto = goalMedia?.type === "image";
    const { vw } = useViewportSize();
    const isMobile = vw * 100 <= 768;

    const convertToCorrectViewBalance = (balance) => {
        if (!balance) return 0;
        if (balance < 1000) return balance;
        return (balance / 1000).toFixed(3);
    };

    const handleGoalCardClick = () => {
        history.push(ROUTE_GOAL_PAGE.replace(":slug", `${slug}-${id}`));
    };

    return (
        <div className={classes.goalContainer} onClick={handleGoalCardClick}>
            {isPhoto ? (
                <img
                    src={goalMedia?.url}
                    className={classes.goalPhoto}
                    alt="Goal"
                />
            ) : (
                <video preload="metadata" className={classes.goalPhoto}>
                    <source src={`${goalMedia?.url}#t=0.1`} />
                </video>
            )}
            <div className={classes.goalDescriptionBox}>
                <div
                    className={`${classes.goalDescriptionColumn} ${classes.fullWidth}`}
                >
                    <div className={classes.goalDescriptionRow}>{name}</div>
                    <div className={classes.goalDescriptionRow}>
                        <ClockIcon
                            width={20}
                            height={20}
                            className={classes.clockIcon}
                        />
                        {goal && (
                            <div className={classes.restTime}>
                                {formatTimeDifference(end_date, t, "until")}
                            </div>
                        )}
                        {` | `}
                        <div
                            className={classes.collectedMoney}
                        >{`${percentage.toFixed(0)}% ${t(
                            "common:funded"
                        )}`}</div>
                    </div>
                    <div className={classes.balanceContainer}>
                        <div className={classes.currentBalance}>
                            <MicromorgiIcon
                                width={isMobile ? 16 : 22}
                                height={isMobile ? 16 : 22}
                            />
                            {convertToCorrectViewBalance(
                                Number(donations_count)
                            )}{" "}
                        </div>
                        <div className={classes.desiredBalance}>
                            <MicromorgiIcon
                                width={isMobile ? 16 : 22}
                                height={isMobile ? 16 : 22}
                            />
                            {convertToCorrectViewBalance(Number(target_amount))}
                        </div>
                    </div>
                </div>
                <div className={`${classes.goalDescriptionColumn}`}>
                    {percentage === 0 ? (
                        <span className={classes.percentageZero}>
                            <span>{percentage}%</span>
                        </span>
                    ) : (
                        <CircularProgress
                            percentage={percentage > 100 ? 100 : percentage}
                            className={
                                percentage < 75 && classes.progressClassName
                            }
                            strokeColor={strokeColor[status]}
                            percentageClassName={classes.percentageClassName}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default GoalCard;

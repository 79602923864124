import { Redirect } from "react-router-dom";
import * as routeNames from "./utilities/constants/routeNames";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import StaticPages from "./pages/staticPages/StaticPages";
import RookieProfile from "./pages/rookieProfilePage/RookieProfile";
import JoinAsLeaderPage from "./pages/joinAsLeaderPage/JoinAsLeaderPage";
import PublicRoute from "./routeGuards/PublicRoute";
import FaqLeader from "./pages/faq/FaqLeader";
import FaqRookie from "./pages/faq/FaqRookie";
import GoalPage from "./pages/goalPage/GoalPage";
import AnonymousPage from "./pages/anonPage/AnonymousPage";
import LinkAccounts from "./pages/LinkAccounts/LinkAccounts";
import CompetitionWaivers from "./pages/competitionWaivers/CompetitionWaivers";
import PaymentsStatus from "./pages/paymentsStatus/PaymentsStatus";
import WelcomePage from "./pages/welcomePage/WelcomePage";
import { Checkout } from "./pages/checkout/Checkout";
import WelcomeRookiePage from "./pages/welcomeRookiePage/WelcomeRookiePage";

const routes = [
    {
        exact: true,
        path: routeNames.ROUTE_HOME,
        component: WelcomePage,
        isDarkTheme: false, //true,
        meta: {
            title: "pages_titles.home.title",
            ogTitle: "pages_titles.home.ogTitle",
        },
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_LANDING_GUEST,
        component: AnonymousPage,
        isDarkTheme: false, //true,
        meta: {},
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_LANDING_ROOKIE,
        component: WelcomeRookiePage,
        fullWidth: true,
        isDarkTheme: false,
        meta: {},
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: `${routeNames.ROUTE_LANDING_ROOKIE}/:role`,
        component: WelcomeRookiePage,
        fullWidth: true,
        isDarkTheme: false,
        meta: {},
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_FAQ_ROOKIE,
        component: FaqRookie,
        meta: {
            title: "pages_titles.faq.title",
            ogTitle: "pages_titles.faq.ogTitle",
            ogDescription: "pages_titles.faq.ogDescription",
            ogImage: "",
        },
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_FAQ_LEADER,
        component: FaqLeader,
        meta: {
            title: "pages_titles.faq.title",
            ogTitle: "pages_titles.faq.ogTitle",
            ogDescription: "pages_titles.faq.ogDescription",
            ogImage: "",
        },
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_NOT_FOUND,
        component: PageNotFound,
        breadcrumb: "page_not_found",
        meta: {
            title: "pages_titles.page_not_found.title",
            ogTitle: "pages_titles.page_not_found.ogTitle",
            ogDescription: "pages_titles.page_not_found.ogDescription",
            ogImage: "",
        },
        routeType: PublicRoute,
        status: 404,
    },
    {
        exact: true,
        path: routeNames.ROUTE_WHO_WE_ARE,
        component: StaticPages,
        breadcrumb: "who_we_are",
        meta: {
            title: "pages_titles.who_we_are.title",
            ogTitle: "pages_titles.who_we_are.ogTitle",
            ogDescription: "pages_titles.who_we_are.ogDescription",
            ogImage: "",
        },
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_A_WORD_FROM_PRESIDENT,
        component: StaticPages,
        breadcrumb: "a_word_from_president",
        meta: {
            title: "pages_titles.a_word_from_president.title",
            ogTitle: "pages_titles.a_word_from_president.ogTitle",
            ogDescription: "pages_titles.a_word_from_president.ogDescription",
            ogImage: "",
        },
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_OUR_MISSION,
        component: StaticPages,
        breadcrumb: "our_mission",
        meta: {
            title: "pages_titles.our_mission.title",
            ogTitle: "pages_titles.our_mission.title.ogTitle",
            ogDescription: "pages_titles.our_mission.ogDescription",
            ogImage: "",
        },
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_WHAT_IS_MORGI,
        component: StaticPages,
        breadcrumb: "what_is_morgi",
        meta: {
            title: "pages_titles.what_is_morgi.title",
            ogTitle: "pages_titles.what_is_morgi.ogTitle",
            ogDescription: "pages_titles.what_is_morgi.ogDescription",
            ogImage: "",
        },
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_COOKIE_POLICY,
        component: StaticPages,
        breadcrumb: "cookie_policy",
        meta: {
            title: "pages_titles.cookie_policy.title",
            ogTitle: "pages_titles.cookie_policy.ogTitle",
            ogDescription: "pages_titles.cookie_policy.ogDescription",
            ogImage: "",
        },
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_PRIVACY_POLICY,
        component: StaticPages,
        breadcrumb: "privacy_policy",
        meta: {
            title: "pages_titles.privacy_policy.title",
            ogTitle: "pages_titles.privacy_policy.ogTitle",
            ogDescription: "pages_titles.privacy_policy.ogDescription",
            ogImage: "",
        },
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_TOS_ROOKIE,
        component: StaticPages,
        breadcrumb: "tos",
        meta: {
            title: "pages_titles.tos.title",
            ogTitle: "pages_titles.tos.ogTitle",
            ogDescription: "pages_titles.tos.ogDescription",
            ogImage: "",
        },
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_TOS_LEADER,
        component: StaticPages,
        breadcrumb: "tos",
        meta: {
            title: "pages_titles.tos.title",
            ogTitle: "pages_titles.tos.ogTitle",
            ogDescription: "pages_titles.tos.ogDescription",
            ogImage: "",
        },
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_COMMUNITY_GUILDINES,
        component: StaticPages,
        breadcrumb: "community-guidlines",
        meta: {
            title: "pages_titles.community_guidelines.title",
            ogTitle: "pages_titles.community_guidelines.ogTitle",
            ogDescription: "pages_titles.community_guidelines.ogDescription",
            ogImage: "",
        },
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_COMPETITION,
        component: CompetitionWaivers,
        breadcrumb: "competition",
        meta: {
            title: "Morgis",
            ogTitle: "pages_titles.community_guidelines.ogTitle",
        },
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_JOIN_AS_LEADER,
        component: JoinAsLeaderPage,
        meta: {
            title: "pages_titles.join_as_leader.title",
            ogTitle: "pages_titles.join_as_leader.ogTitle",
            ogDescription: "pages_titles.join_as_leader.ogDescription",
            ogImage: "",
        },
        routeType: PublicRoute,
    },
    {
        routeType: PublicRoute,
        exact: true,
        path: routeNames.ROUTE_LINK_ACCOUNTS,
        component: LinkAccounts,
        breadcrumb: "link-accounts",
    },
    {
        routeType: PublicRoute,
        exact: true,
        path: routeNames.ROUTE_CHECK_PAYMENT,
        component: PaymentsStatus,
        breadcrumb: "check-payment",
    },
    {
        exact: true,
        path: routeNames.ROUTE_ROOKIE_PROFILE,
        component: RookieProfile,
        breadcrumb: "rookie-profile",
        fullWidth: true,
        meta: {
            title: "pages_titles.rookie_profile.title",
        },
        routeType: PublicRoute,
        lockCarousel: true,
    },
    {
        exact: true,
        path: routeNames.ROUTE_CHECKOUT,
        component: Checkout,
        breadcrumb: "checkout",
        meta: {
            title: "pages_titles.rookie_profile.title",
        },
        routeType: PublicRoute,
    },
    {
        exact: true,
        path: routeNames.ROUTE_GOAL_PAGE,
        component: GoalPage,
        breadcrumb: "goal-page",
        meta: {
            title: "pages_titles.goal_page.title",
        },
        routeType: PublicRoute,
        userUrlParams: true,
        lockCarousel: true,
    },
    {
        routeType: Redirect,
        to: "/404",
    },
];

export default routes;
